@import './styles/styles';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Fira Code', 'Lato', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

::placeholder {
  text-overflow: ellipsis;
}

.modal-content {
  border: 1px solid black !important;
  border-radius: 10px;
  .modal-header {
    border-radius: 10px 10px 0 0;
  }
  .modal-footer {
    border-radius: 0 0 10px 10px;
  }
}

.modal-body {
  color: var(--primary-scale-90);
}

*,
*::after,
*::before {
  box-sizing: border-box !important;
}

input {
  &:active,
  &:focus {
    border-color: var(--accent) !important;
    box-shadow: none !important;
    outline: none !important;
  }
}

#root {
  position: relative;
}
