.PrimaryNavbar {
    height: 100px;
    background-color: var(--primary-scale-100);
    position: relative;
    z-index: 100;
    flex: 1;
}

.PrimaryNavbarContent {
    height: 100%;
    display: flex;
    align-items: center;
}

.LogoShort {
    height: 50px;
}

.PhoneContact {
    color: var(--white);
    padding-left: 1rem;

    &:last-child {
        padding-left: 2rem;
    }
}
