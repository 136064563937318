@import "/src/styles/core";

.Button {
    height: 44px;

    &.Borderless {
        border: none !important;
    }

    &.ButtonAutoHeight {
        height: auto !important;
    }

    .ButtonContent {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &.accent {
        background-color: var(--accent) ;
        color: var(--primary-scale-70) !important;
    }

    &.light {
        background-color: var(--white);
        border: 2px solid var(--outline-scale-80);

        &:hover {
            background-color: var(--accent);
            color: var(--white);
        }
    }

    &.gray {
        background-color: var(--outline-scale-90);
        border: none;
        color: var(--accent);

        &:hover {
            background-color: var(--accent);
            color: var(--white);
        }
    }

    &.light-accent {
        background-color: var(--outline-scale-80);
        color: var(--accent);
    }

    &.white-accent-dotted {
        background-color: var(--white);
        color: var(--accent);
        // border: 1px dotted var(--accent);

        background-color: var(--white);
        color: var(--accent);
        background-image: linear-gradient(to right, #ffffff 50%, white 50%);
        background-position: top;
        background-size: 10px 1px;
        background-repeat: repeat-x;
        border-color: var(--accent);
        border-width: 1px;
        border-style: dashed;
    }

    &.dark-gray {
        background-color: var(--outline-scale-80);
        color: var(--primary-scale-90);
    }

    &:active,
    &:focus {
        box-shadow: none !important;
    }

    .IconLabel {
        padding: 0 0.5rem;
    }
}

.Link {
    height: 44px;

    &.accent {
        background-color: transparent !important;
        color: var(--accent) !important;
        font-weight: bold;
    }

    &:active,
    &:focus {
        box-shadow: none !important;
    }

    .IconLabel {
        padding: 0 0.5rem;
    }
}

.AsNormalLink {
    font-weight: normal !important;
    text-decoration: underline !important;
    font-size: 14px !important;
}

.AsNormalWeight {
    font-weight: normal !important;
    font-size: 14px !important;
}

.Label {
    color: var(--primary-scale-90);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    font-size: 14px;
}

.InnerLabel {
    display: none;
}

@include media-breakpoint-up(lg) {
    .InnerLabel {
        display: inline-block;
    }
}
