.Container {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.8);;
    z-index: 99999;

    .Loader {
        position: relative;
        width: 100%;
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
        & > img {
            width: 300px;
            height: 300px;
        }
    }
}
