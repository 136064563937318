.Avatar {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: var(--accent);
    overflow: hidden;
    position: relative;

    .LockShade {
        width: 100%;
        height: 100%;
        transform: scale(1.3) translateY(5px);
        opacity: 0.1;
    }

    .Initials {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--white);
        font-size: 0.75em;
    }
}
