.auctionDateDisabled {
  background-color: #e9ecef;
}
.error {
  color: #ff0000;
  font-weight: 400;
  font-size: 12px;
  height: 18px;
  margin-top: 2px;
}
.label_title {
  background: #fafafa;
  padding: 8px 24px;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  color: #808080;
  & > span {
    font-weight: 400;
    font-size: 16px;
    color: #808080;
  }
}
.contact_person_wrapper {
  .contact_person_form {
    display: grid;
    grid-template-columns: calc(50% - 10px) calc(50% - 10px);
    column-gap: 20px;
    padding: 16px 25px 0;
    @media screen and (max-width: 767px) {
      grid-template-columns: 100%;
    }
    .input_wrapper {
      &.has_error {
        input {
          border-color: #ff0000 !important;
          &:focus,
          &:active {
            border-color: red !important;
          }
        }
      }
      label {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        color: #404040;
        padding-bottom: 5px;
      }
      input {
        width: 100%;
        background: #ffffff;
        border: 1px solid #dfdfdf;
        border-radius: 6px;
        padding: 8px;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        color: #323232;
        &:focus,
        &:active {
          border-color: #dfdfdf !important;
        }
      }
      .error {
        color: #ff0000;
        font-weight: 400;
        font-size: 12px;
        height: 18px;
        margin-top: 2px;
      }
    }
  }
}

.data_picker_wrapper {
  div:last-of-type {
    margin-bottom: 0;
    position: relative;
    input {
      border: none;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      color: #323232;
      cursor: pointer;
      &:disabled {
        background: transparent;
      }
    }
    div {
      position: absolute;
      right: 10px;
    }
  }
}

.ad_time_and_criteria_wrapper {
  margin-top: 20px;
  .form_wrapper {
    padding: 8px 24px;
    display: grid;
    grid-template-columns: 50% 50%;
    @media screen and (max-width: 767px) {
      grid-template-columns: 100%;
    }
    label {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      color: #404040;
      padding-bottom: 5px;
    }
    input {
      width: 80%;
      background: #ffffff;
      border: 1px solid #dfdfdf;
      border-radius: 6px;
      padding: 8px;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      color: #323232;
      &:focus,
      &:active {
        border-color: #dfdfdf !important;
      }
    }
    .input_wrapper {
      @media screen and (max-width: 767px) {
        margin-top: 10px;
      }
      label {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        color: #404040;
        padding-bottom: 5px;
      }
      .radio_buttons_wrapper {
        display: flex;
        justify-content: space-between;
        max-width: 452px;
        .criteria_wrapper {
          display: flex;
          flex-direction: row;
          label {
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            color: #404040;
            display: flex;
            vertical-align: middle;
            input {
              width: 24px;
              height: 24px;
              margin-right: 8px;
            }
          }
        }
      }
    }
  }
}

.auction_wrapper {
  .inputs_wrapper {
    padding: 8px 24px;
    display: grid;
    grid-template-columns: calc(25% + 5px) auto;
    @media screen and (max-width: 767px) {
      grid-template-columns: 100%;
    }
    .switch_wrapper {
      .switch {
        & > label {
          display: inline;
          padding: 0;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          color: #808080;
          &.active {
            color: #323232;
          }
          &:first-of-type {
            margin-right: 6px;
          }
          &:last-of-type {
            margin-left: 8px;
          }
        }
        .custom_switch {
          label {
            padding-top: 0.5rem;
            &::after {
              margin-left: 2px;
              background: #0044bb;
              opacity: 0.16;
              border-radius: 50px;
            }
          }
        }
      }
    }
    .auction_date {
      display: grid;
      grid-template-columns: repeat(3, calc(33.333% - 13px));
      column-gap: 20px;
      @media screen and (max-width: 767px) {
        grid-template-columns: 100%;
      }
      .has_error {
        .error {
          color: #ff0000;
          font-weight: 400;
          font-size: 12px;
          height: 18px;
          margin-top: 2px;
        }
      }
      .time_from_wrapper,
      .time_to_wrapper {
        @media screen and (max-width: 767px) {
          margin-top: 10px;
        }
        &.has_error {
          select {
            border-color: #ff0000 !important;
          }
          .error {
            color: #ff0000;
            font-weight: 400;
            font-size: 12px;
            height: 18px;
            margin-top: 2px;
          }
        }
        .input_wrapper {
          position: relative;
          select {
            cursor: pointer;
            border: 1px solid #dfdfdf;
            height: 40px;
            border-radius: 6px;
            &:focus {
              border-color: #dfdfdf;
              box-shadow: none;
            }
            &:focus-visible {
              outline: none;
            }
          }
          & > img {
            position: absolute;
            right: 10px;
            top: 10px;
          }
        }
      }
    }
  }
}

.administrative_info_wrapper {
  margin-top: 10px;
  .administrative_info {
    padding: 12px 24px;
    .row_one {
      label {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        color: #404040;
      }
      .inputs_wrapper {
        display: grid;
        grid-template-columns: repeat(2, calc(25% - 15px));
        column-gap: 20px;
        // align-items: center;
        @media screen and (max-width: 767px) {
          grid-template-columns: 100%;
          row-gap: 20px;
        }
        .input_wrapper {
          position: relative;
          select {
            cursor: pointer;
            border: 1px solid #dfdfdf;
            height: 40px;
            border-radius: 6px;
            &:focus {
              border-color: #dfdfdf;
              box-shadow: none;
            }
            &:focus-visible {
              outline: none;
            }
          }
          & > img {
            position: absolute;
            right: 10px;
            top: 10px;
          }
          &.has_error {
            select {
              border-color: #ff0000 !important;
            }
            .error {
              color: #ff0000;
              font-weight: 400;
              font-size: 12px;
              height: 18px;
              margin-top: 2px;
            }
          }
        }
      }
    }
    .row_two {
      margin-top: 10px;
      display: grid;
      grid-template-columns: repeat(2, calc(50% - 10px));
      column-gap: 20px;
      @media screen and (max-width: 767px) {
        grid-template-columns: 100%;
        row-gap: 20px;
      }
      .inputs_wrapper {
        label {
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          color: #404040;
        }
        .inputs_container {
          display: grid;
          grid-template-columns: repeat(2, calc(50% - 10px));
          column-gap: 20px;
          align-items: center;
          @media screen and (max-width: 500px) {
            grid-template-columns: 100%;
            row-gap: 20px;
          }
          .input_wrapper {
            position: relative;
            select {
              cursor: pointer;
              border: 1px solid #dfdfdf;
              height: 40px;
              border-radius: 6px;
              &:focus {
                border-color: #dfdfdf;
                box-shadow: none;
              }
              &:focus-visible {
                outline: none;
              }
            }
            & > img {
              position: absolute;
              right: 10px;
              top: 10px;
            }
          }
        }
      }
    }
  }
}

.technical_info_wrapper {
  .technical_info {
    padding: 16px 24px;
    label {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      color: #404040;
    }
    .uploaded_docs {
      display: grid;
      grid-template-columns: repeat(2, calc(50% - 10px));
      column-gap: 20px;
      row-gap: 5px;
      @media screen and (max-width: 767px) {
        grid-template-columns: 100%;
      }
      .uploaded_doc {
        display: grid;
        grid-template-columns: 24px auto 24px;
        column-gap: 5px;
        align-items: center;
        border: 1px solid #dfdfdf;
        border-radius: 6px;
        padding: 14px 10px;
        margin-bottom: 10px;
        max-width: 550px;
        .document_icon {
          width: 24px;
          height: 24px;
          mask-image: url('./../../../../../assets/icons/FileText.svg');
          mask-size: contain;
          background: #0044bb;
        }
        .file_name {
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          color: #0044bb;
          cursor: pointer;
        }
        .close_icon {
          width: 24px;
          height: 24px;
          background-image: url('./../../../../../assets/icons/XSquare.svg');
          background-size: cover;
          cursor: pointer;
        }
      }
    }
    .upload_doc {
      &.has_error {
        .attach_wrapper {
          border-color: #ff0000 !important;
        }
        .error {
          color: #ff0000;
          font-weight: 400;
          font-size: 12px;
          height: 18px;
          margin-top: 2px;
        }
      }
      .attach_wrapper {
        @media screen and (max-width: 500px) {
          width: 100%;
        }
        .icon {
          --webkit-mask-image: url('../../../../../assets/icons/Plus.svg');
          mask-image: url('../../../../../assets/icons/Plus.svg');
        }
      }
    }
  }
}

.buttons_wrapper {
  display: flex;
  padding: 20px 0;
  margin: 0 25px;
  align-items: center;
  margin-top: 20px;
  border-top: 1px solid #dfdfdf;

  .delete_btn {
    color: #ffffff;
    background: red;
    font-weight: 500;
  }
  .left_buttons {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-right: auto;
  }
  .divider {
    display: flex;
    .cancel_btn {
      color: #0044bb;
      background: #ffffff;
    }
    .save_btn {
      &:disabled {
        opacity: 0.3;
      }
    }
  }

  .base_btn {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    text-align: center;
    min-width: 170px;
    max-width: 100%;
    height: 44px;
    border-radius: 4px;
    border: none;
  }
}

.basic_button {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid transparent;
  background-color: #0044bb;
  border-radius: 6px;
  padding: 8px 20px;
  cursor: pointer;
  width: 200px;
  transition: 0.3s;
  @media screen and (max-width: 500px) {
    width: 100%;
  }
  .icon {
    width: 18px;
    height: 18px;
    background: #ffffff;
    mask-repeat: no-repeat;
    mask-size: contain;
  }
  .text {
    margin-left: 10px;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    color: #ffffff;
  }
  &:hover {
    background-color: #ffffff;
    border: 1px solid #0044bb;
    .text {
      color: #0044bb;
    }
    .icon {
      background: #0044bb;
    }
  }
}
