.table_container {
  margin-top: 15px;

  .table {
    width: 100%;
    table-layout: fixed;

    input[type='checkbox'] {
      width: 15px;
      height: 15px;
      position: relative;
      top: 2px;
    }

    thead {
      border: transparent;

      tr {
        th {
          font-weight: 600;
          font-size: 14px;
          color: #252830;
          background: #fafafa;
          padding: 22px 8px;
          border: none;
          position: relative;
          height: 70px;
        }
      }
    }

    tbody {
      border: none;

      tr {
        height: 60px;
      }

      td {
        border: none;
        border-bottom: 2px solid #fafafa;
        vertical-align: middle;
        font-weight: 400;
        font-size: 14px;
        color: #252830;
        max-width: 0;
        width: 15%;

        &:nth-child(1) {
          width: 55%;
        }

        .name {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }
  }
}

.buttons_wrapper {
  display: grid;
  grid-template-columns: repeat(3, max-content);
  grid-column-gap: 18px;
  padding: 0 4px 5px;
  border-bottom: 1px solid #e0e0e0;
  @media screen and (max-width: 767px) {
    overflow-x: auto;
    overflow-y: hidden;
  }
  .button {
    font-weight: 400;
    font-size: 16px;
    color: #323232;
    padding: 0 6px;
    cursor: pointer;
    position: relative;
    .badge {
      font-weight: 400;
      font-size: 12px;
      color: #ffffff;
      background-color: #0044bb !important;
    }
    .text {
      font-weight: 400;
      font-size: 12px;
    }
    &.active {
      color: #0044bb;
      &::after {
        content: '';
        position: absolute;
        left: 0px;
        bottom: -7px;
        width: 100%;
        height: 3px;
        border-bottom: 3px solid #0044bb;
        border-radius: 20px;
        z-index: 2;
      }
    }
  }
}

.name_form {
  position: relative;

  .form_actions {
    position: absolute;
    top: 100%;
    right: 0;
    margin-top: 2px;
    display: flex;
    gap: 1px;
    z-index: 2;
  }
}
