.offers_screen_wrapper {
  height: 100%;
  .buttons_wrapper {
    display: grid;
    grid-template-columns: max-content max-content;
    grid-column-gap: 18px;
    padding: 0 4px 5px;
    border-bottom: 1px solid #e0e0e0;
    @media screen and (max-width: 767px) {
      overflow-x: auto;
      overflow-y: hidden;
    }
    .button {
      font-weight: 400;
      font-size: 16px;
      color: #323232;
      padding: 0 6px;
      cursor: pointer;
      position: relative;
      .badge {
        font-weight: 400;
        font-size: 12px;
        color: #ffffff;
        background-color: #0044bb !important;
      }
      .text {
        font-weight: 400;
        font-size: 12px;
      }
      &.active {
        color: #0044bb;
        &::after {
          content: '';
          position: absolute;
          left: 0px;
          bottom: -7px;
          width: 100%;
          height: 3px;
          border-bottom: 3px solid #0044bb;
          border-radius: 20px;
          z-index: 2;
        }
      }
    }
  }

  .table_wrapper {
    margin-top: 20px;
    margin-bottom: 10px;
    height: 90%;
    .table_container {
      height: 92%;
      .table_wrapper_inner {
        height: 100%;
        & > div:first-child {
          height: 85%;
          margin-bottom: 10px;
          table {
            height: 100%;
          }
        }
        .offers_table {
          overflow-x: auto;
          display: block;
          width: 100%;
          white-space: nowrap;
          input[type='checkbox'] {
            width: 15px;
            height: 15px;
            position: relative;
            top: 2px;
          }
          thead {
            border: transparent;
            tr {
              text-align: center;
              vertical-align: middle;
              white-space: break-spaces;
              th {
                padding: 0 5px;
                font-weight: 600;
                font-size: 14px;
                color: #252830;
                background: #fafafa;
                border: none;
                position: relative;
                height: 70px;
                &:not(:last-of-type) {
                  &::after {
                    content: '';
                    border-right: 1px solid #ebebeb;
                    position: absolute;
                    right: 0;
                    top: 12px;
                    height: 46px;
                  }
                }
                text-align: center;
                // white-space: normal;
                &:nth-child(1),
                &:nth-child(2),
                &:nth-child(3),
                &:nth-child(7),
                &:nth-child(8),
                &:nth-child(9) {
                  max-width: 105px;
                  min-width: 105px;
                }
                &:nth-child(6),
                &:nth-child(4) {
                  max-width: 400px;
                  min-width: 400px;
                }

                &:nth-child(5) {
                  max-width: 250px;
                  min-width: 250px;
                }

                &:nth-child(10) {
                  span {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    color: #202020;
                    margin-right: 25px;
                  }
                  a {
                    background: #0044bb;
                    border-radius: 6px;
                    padding: 10px 8px;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 12px;
                    color: #fbfbfb;
                    text-decoration: none;
                    margin-right: 27px;
                    span {
                      background-color: #ffffff !important;
                      border-radius: 4px;
                      padding: 4px;
                      font-style: normal;
                      font-weight: 400;
                      font-size: 12px;
                      color: #0044bb;
                      margin: 0;
                      margin-left: 8px;
                    }
                  }
                }

                .sort_wrapper {
                  display: grid;
                  grid-auto-rows: max-content max-content;
                  grid-row-gap: 2px;
                  margin-left: 5px;
                  .caret {
                    display: inline-block;
                    width: 0;
                    height: 0;
                    margin-left: 2px;
                    vertical-align: middle;
                    border-top: 4px dashed rgba(0, 0, 0, 0.25);
                    border-top: 4px solid \9;
                    border-right: 4px solid transparent;
                    border-left: 4px solid transparent;
                  }
                  .caret_up {
                    content: '';
                    border-top: 0;
                    border-bottom: 4px dashed rgba(0, 0, 0, 0.25);
                    border-bottom: 4px solid \9;
                  }
                }
              }
            }
          }
          tbody {
            border: none;
            tr {
              height: 60px;
              td {
                border: none;
                border-bottom: 2px solid #fafafa;
                vertical-align: middle;
                font-weight: 400;
                font-size: 14px;
                color: #252830;
                text-overflow: ellipsis;
                overflow: hidden;
                cursor: pointer;
                &:nth-child(1),
                &:nth-child(2),
                &:nth-child(3),
                &:nth-child(7),
                &:nth-child(8),
                &:nth-child(9) {
                  max-width: 105px;
                  min-width: 105px;
                }
                &:nth-child(6),
                &:nth-child(4) {
                  max-width: 400px;
                  min-width: 400px;
                }

                &:nth-child(5) {
                  max-width: 250px;
                  min-width: 250px;
                }
                &:nth-child(1),
                &:nth-child(2),
                &:nth-child(3),
                &:nth-child(9) {
                  text-align: center;
                }

                &:nth-child(11) {
                  span {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    color: #202020;
                    margin-right: 25px;
                  }
                  a {
                    background: #0044bb;
                    border-radius: 6px;
                    padding: 10px 8px;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 12px;
                    color: #fbfbfb;
                    text-decoration: none;
                    margin-right: 27px;
                    span {
                      background-color: #ffffff !important;
                      border-radius: 4px;
                      padding: 4px;
                      font-style: normal;
                      font-weight: 400;
                      font-size: 12px;
                      color: #0044bb;
                      margin: 0;
                      margin-left: 8px;
                    }
                  }
                }
              }
            }
          }
        }
      }
      .pagination_wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 10%;
        @media screen and (max-width: 767px) {
          flex-wrap: wrap;
        }
        & > span {
          font-weight: 400;
          font-size: 14px;
          color: #252830;
          @media screen and (max-width: 767px) {
            min-width: 100%;
          }
        }
        & > div {
          display: flex;
          align-items: center;
          @media screen and (max-width: 767px) {
            margin-top: 10px;
            justify-content: space-between;
            width: 100%;
          }
          & > ul {
            margin: 0 16px 0 0;
            li {
              &:not(:last-child) {
                margin-right: 8px;
              }
              a {
                border: 1px solid #d9d9d9;
                border-radius: 2px;
                background: #ffffff;
                width: 32px;
                height: 32px;
                color: #252830;
                font-weight: 400;
                font-size: 14px;
                &:focus {
                  box-shadow: none;
                }
              }
            }
          }
          & > span {
            button {
              background: #ffffff;
              border: 1px solid #d9d9d9;
              border-radius: 2px;
              height: 32px;
              padding: 4px 12px;
              color: #252830;
              font-weight: 400;
              font-size: 14px;
              display: flex;
              &:focus {
                box-shadow: none;
              }
              &::after {
                content: '';
                position: relative;
                right: 0px;
                bottom: 4px;
                border: none;
                width: 10px;
                height: 30px;
                background-image: url('./../../../assets//icons/ArrowDown.svg');
                background-position: center;
                background-repeat: no-repeat;
                background-size: 10px;
              }
            }
            & > ul {
              right: 0;
              bottom: 32px;
              min-width: 50px;
              li {
                a {
                  text-decoration: none;
                  font-weight: 400;
                  font-size: 14px;
                  color: #252830;
                }
              }
            }
          }
        }
      }
    }
  }
  // status colors
  .status {
    height: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    span {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      margin-right: 8px;
    }
    &.declined {
      color: #ff0000;
      span {
        background-color: #ff0000;
      }
    }
    &.accepted {
      color: #00d015;
      span {
        background-color: #00d015;
      }
    }
    &.auction {
      color: #0044bb;
      span {
        background-color: #0044bb;
      }
    }
    &.in_progress {
      color: #faa526;
      span {
        background-color: #faa526;
      }
    }
    &.finished {
      color: #01b1e9;
      span {
        background-color: #01b1e9;
      }
    }
    &.canceled {
      color: #808080;
      span {
        background-color: #808080;
      }
    }
  }
}
