.Tab {
    font-size: 16px;
    padding: 0.5rem 1.5rem;
    cursor: pointer;

    .Counter {
        display: none;
    }

    &.Active {
        color: var(--accent);
        font-weight: bold;
        position: relative;

        &::before {
            content: "";
            position: absolute;
            height: 3px;
            background-color: var(--accent);
            border-radius: 20px;
            bottom: -1px;
            left: 0;
            width: 100%;
        }

        .Badge {
            display: inline-block;
            margin-left: 0.5rem;
            background-color: var(--accent);
            border-radius: 4px;
            color: var(--white);
            font-weight: normal !important;
            padding: 0.1rem 0.5rem;
            font-size: 12px;
        }
    }
}

.ForcedBadge {
    margin-left: 0.35rem;
    font-size: 0.875rem;
}
