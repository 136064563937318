.sort_wrapper {
  display: grid;
  grid-auto-rows: max-content max-content;
  grid-row-gap: 2px;
  margin-left: 5px;

  .caret {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 2px;
    vertical-align: middle;
    border-top: 4px dashed rgba(0, 0, 0, 0.25);
    border-top: 4px solid \9;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
  }

  .caret_up {
    content: '';
    border-top: 0;
    border-bottom: 4px dashed rgba(0, 0, 0, 0.25);
    border-bottom: 4px solid \9;
  }
}
