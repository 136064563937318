.ModalHeader {
    height: 70px;
    display: flex;
    width: 100%;
    align-items: center;
    background-color: var(--outline-scale-80);
    border-radius: 7px 7px 0 0;
    padding: 0 1rem;

    .ModalTitle {
        color: var(--primary-scale-90);
        font-size: 24px;
        text-align: center;
        font-weight: 500;
        flex: 1;
    }

    .CloseAction {
        button {
            border: none;
            background-color: var(--outline-scale-100);
            width: 30px;
            height: 30px;
            border-radius: 50%;
        }
    }
}

.AcceptButton {
    button {
        width: 185px;
    }
}

.FooterInfo {
    text-align: center;
    padding-bottom: 1.5rem;
    font-size: 14px;
    color: var(--primary-scale-90);
}
