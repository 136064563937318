@import '/src/styles/core';

.page {
  display: grid;

  // max-height: 100vh;
  // overflow-y: auto;

  grid-template-areas:
    'sidebar primary-nav'
    'sidebar secondary-nav'
    'sidebar page-content'
    'sidebar page-footer';
  grid-template-rows: 100px 220px;
  grid-template-columns: 0;

  &.sidebar-hidden {
    grid-template-areas:
      'primary-nav'
      'secondary-nav'
      'page-content'
      'page-footer';
    grid-template-columns: 100%;
  }

  &.fullscreen {
    grid-template-areas:
      'sidebar page-content'
      'sidebar page-content';
    grid-template-rows: 100px calc(100vh - 100px);
  }

  .sidebar {
    grid-area: sidebar;
  }

  .primary-nav {
    grid-area: primary-nav;
  }

  .secondary-nav {
    grid-area: secondary-nav;
  }

  &:not(.fullscreen) .page-content {
    grid-area: page-content;
    margin-top: -12rem;
    // padding-bottom: 3rem;
    min-height: calc(84vh - 60px);
    overflow: hidden;
  }

  &.borderless-fullscreen .page-content {
    grid-area: page-content;
    padding-bottom: 0 !important;
  }

  &.fullscreen .page-content {
    grid-area: page-content;
    margin: 0;
  }

  .page-footer {
    grid-area: page-footer;
  }
}

@include media-breakpoint-up(xl) {
  .page {
    grid-template-columns: 340px;
  }
}
