.ad_form {
  padding: 16px 25px 0;
  &:not(:last-of-type) {
    .action_btns_wrapper {
      .add_more {
        display: none;
      }
    }
  }
  .inputs_wrapper {
    display: grid;
    grid-template-columns: calc(100% - 275px) auto;
    column-gap: 20px;
    @media screen and (max-width: 767px) {
      grid-template-columns: 100%;
    }
  }
  .input_wrapper {
    &.has_error {
      input,
      select,
      textarea {
        border-color: #ff0000 !important;
        &:focus,
        &:active {
          border-color: red !important;
        }
      }
    }
    label {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      color: #404040;
      padding-bottom: 5px;
    }
    input {
      background: #ffffff;
      border: 1px solid #dfdfdf;
      border-radius: 6px;
      padding: 8px;
      width: 100%;
      &:focus,
      &:active {
        border-color: #dfdfdf !important;
      }
    }
    textarea {
      width: 100%;
      background: #ffffff;
      border: 1px solid #dfdfdf;
      border-radius: 6px;
      padding: 8px 8px 25px;
      resize: none;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      color: #323232;
      &:focus,
      &:active {
        border-color: #dfdfdf !important;
      }
      &:focus-visible {
        outline: none;
      }
    }
    select {
      height: 42px;
      background-image: url('../../../../../../assets/icons/CaretDownAccent.svg');
      &:focus,
      &:active {
        border-color: #dfdfdf !important;
        box-shadow: none;
      }
      &:focus-visible {
        outline: none;
      }
    }
    .input_container {
      position: relative;
      & > .count_characters {
        position: absolute;
        right: 22px;
        bottom: 10px;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        color: #828282;
        background-color: #ffffff;
        padding: 0 2px;
        span {
          color: #0044bb;
        }
      }

      & > .icons_wrapper {
        position: absolute;
        right: 10px;
        top: 12px;
        display: flex;
        flex-direction: column;
        img {
          &:first-of-type {
            margin-bottom: 1px;
          }
          &:last-of-type {
            margin-top: 1px;
          }
        }
      }
    }
    .error {
      color: #ff0000;
      font-weight: 400;
      font-size: 12px;
      height: 18px;
      margin-top: 2px;
    }
  }
  .attach_images_wrapper {
    .images_wrapper {
      display: flex;
      flex-wrap: wrap;
      .image_wrapper {
        border: 2px solid #e3e7ee;
        border-radius: 10px;
        width: 216px;
        height: 216px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        margin-bottom: 8px;
        @media screen and (max-width: 500px) {
          width: 100%;
          &:not(:last-of-type) {
            margin-right: 0px !important;
          }
        }
        &:not(:last-of-type) {
          margin-right: 8px;
        }
        img {
          max-width: 214px;
          max-height: 214px;
          border-radius: 10px;
        }
        .icon_wrapper {
          position: absolute;
          bottom: 12px;
          cursor: pointer;
          width: 48px;
          height: 48px;
          display: flex;
          align-items: center;
          justify-content: center;
          background: rgba(227, 231, 238, 0.7);
          border-radius: 100%;
          .icon {
            width: 30px;
            height: 30px;
            mask-image: url('./../../../../../../assets/icons/TrashIcon.svg');
            mask-size: contain;
            mask-repeat: no-repeat;
            background: #25262d;
          }
        }
      }
    }
    .attach_btn_wrapper {
      margin-top: 12px;
      display: flex;
      justify-content: space-between;
      label {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        color: #323232;
      }
      .attach_button {
        .icon {
          -webkit-mask-image: url('../../../../../../assets/icons/Image.svg');

          mask-image: url('../../../../../../assets/icons/Image.svg');
        }
      }
    }
  }
  .horizontal_line {
    margin-top: 24px;
    border-bottom: 1px solid #dfdfdf;
  }
  .action_btns_wrapper {
    margin-top: 16px;
    display: flex;
    justify-content: flex-end;
    @media screen and (max-width: 500px) {
      flex-direction: column;
    }
    .remove {
      background: #ff0000;
      border-radius: 6px;
      border: none;
      font-style: normal;
      font-size: 15px;
      color: #fbfbfb;
    }
    .add_more {
      margin-left: 10px;

      @media screen and (max-width: 500px) {
        margin-left: 0;
        margin-top: 10px;
      }
      .icon {
        --webkit-mask-image: url('../../../../../../assets/icons/Plus.svg');
        --webkit-mask-size: contain;
        mask-image: url('../../../../../../assets/icons/Plus.svg');
        mask-size: contain;
      }
    }
  }
  .base_btn {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    text-align: center;
    min-width: 170px;
    max-width: 100%;
    height: 44px;
    border-radius: 4px;
    border: none;
  }
  .additional_documents_wrapper {
    .label_title {
      padding-bottom: 0.5rem;
    }
    .uploaded_docs {
      padding: 20px 0;
      display: flex;
      flex-wrap: wrap;
      .uploaded_doc {
        display: grid;
        grid-template-columns: 24px auto 24px;
        column-gap: 5px;
        align-items: center;
        border: 1px solid #dfdfdf;
        border-radius: 6px;
        padding: 14px 10px;
        margin-bottom: 10px;
        max-width: fit-content;
        &:not(:last-of-type) {
          margin-right: 20px;
        }
        .document_icon {
          width: 24px;
          height: 24px;
          mask-image: url('./../../../../../../assets/icons/FileText.svg');
          mask-size: contain;
          background: #0044bb;
        }
        .file_name {
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          color: #0044bb;
          cursor: pointer;
        }
        .close_icon {
          width: 24px;
          height: 24px;
          background-image: url('./../../../../../../assets/icons/XSquare.svg');
          background-size: cover;
          cursor: pointer;
        }
      }
    }
    .button_wrapper {
      .add_additional_docs_btn {
        @media screen and (max-width: 500px) {
          width: 100%;
        }
        .icon {
          --webkit-mask-image: url('../../../../../../assets/icons/Plus.svg');
          --webkit-mask-size: contain;
          mask-image: url('../../../../../../assets/icons/Plus.svg');
          mask-size: contain;
        }
      }
    }
  }
}

.basic_button {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid transparent;
  background-color: #0044bb;
  border-radius: 6px;
  padding: 8px 20px;
  cursor: pointer;
  width: 200px;
  transition: 0.3s;
  @media screen and (max-width: 500px) {
    width: 100%;
  }
  .icon {
    width: 18px;
    height: 18px;
    background: #ffffff;
    mask-repeat: no-repeat;
    mask-size: contain;
  }
  .text {
    margin-left: 10px;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    color: #ffffff;
  }
  &:hover {
    background-color: #ffffff;
    border: 1px solid #0044bb;
    .text {
      color: #0044bb;
    }
    .icon {
      background: #0044bb;
    }
  }
}
