.header_content_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  top: 20px;
  @media screen and (max-width: 767px) {
    display: block;
  }
  .back_btn {
    background: #ffffff;
    border-radius: 16px;
    height: 30px;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    text-decoration-line: underline;
    color: #0044bb;
    border: none;
    padding: 4px 10px;
    text-underline-offset: 3px;
  }
}

.header_container {
  margin-bottom: 10px;
}
.product_wrapper {
  margin-top: 21px;
  display: grid;
  grid-template-columns: auto max-content;
  column-gap: 30px;
  border-bottom: 1px solid #dfdfdf;
  padding-bottom: 16px;
  .product_info {
    .title_wrapper {
      display: grid;
      grid-template-columns: 75px auto;
      column-gap: 20px;
      .image_wrapper {
        width: 75px;
        height: 75px;
        border: 0.5px solid #e3e7ee;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          max-width: 74px;
          max-height: 74px;
        }
      }
      .info_wrapper {
        .title {
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          color: #404040;
        }
        .category {
          margin-top: 8px;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          color: #606060;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
      }
    }
    .description {
      margin-top: 12px;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      color: #323232;
    }
  }
  .product_quantity {
    .title {
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      color: #404040;
    }
    .quantity {
      margin-top: 7px;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      color: #606060;
    }
  }
}
.table_wrapper {
  margin-top: 20px;
  margin-bottom: 10px;
  .table_container {
    margin-top: 15px;
    .offers_table {
      overflow-x: auto;
      display: block;
      width: 100%;
      white-space: nowrap;
      thead {
        border: transparent;
        tr {
          th {
            font-weight: 600;
            font-size: 14px;
            color: #252830;
            background: #fafafa;
            padding: 22px 8px;
            border: none;
            position: relative;
            height: 70px;
            text-align: center;

            &:not(:last-of-type) {
              &::after {
                content: '';
                border-right: 1px solid #ebebeb;
                position: absolute;
                right: 0;
                top: 12px;
                height: 46px;
              }
            }
            &:nth-child(1),
            &:nth-child(2) {
              min-width: 30%;
              width: 30%;
            }

            &:nth-child(3),
            &:nth-child(4),
            &:nth-child(5) {
              min-width: 10%;
              width: 10%;
            }

            .sort_wrapper {
              display: grid;
              grid-auto-rows: max-content max-content;
              grid-row-gap: 2px;
              margin-left: 5px;
              .caret {
                display: inline-block;
                width: 0;
                height: 0;
                margin-left: 2px;
                vertical-align: middle;
                border-top: 4px dashed rgba(0, 0, 0, 0.25);
                border-top: 4px solid \9;
                border-right: 4px solid transparent;
                border-left: 4px solid transparent;
              }
              .caret_up {
                content: '';
                border-top: 0;
                border-bottom: 4px dashed rgba(0, 0, 0, 0.25);
                border-bottom: 4px solid \9;
              }
            }
          }
        }
      }
      tbody {
        border: none;
        tr {
          height: 60px;
          td {
            border: none;
            border-bottom: 1px solid #dfdfdf;
            vertical-align: middle;
            font-weight: 400;
            font-size: 14px;
            color: #252830;
            text-overflow: ellipsis;

            &:nth-child(3),
            &:nth-child(4) {
              text-align: center;
            }
            &:last-of-type {
              button {
                border: none;
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: 14px;
                text-align: center;
                color: #ffffff;
                padding: 9px 15px;
                border-radius: 4px;
                &:focus-visible {
                  outline: none;
                }
                .icon {
                  -webkit-mask-size: contain;
                  mask-size: contain;
                  -webkit-mask-repeat: no-repeat;
                  mask-repeat: no-repeat;
                  background: #ffffff;
                  margin-right: 8px;
                  vertical-align: middle;
                  display: inline-block;
                }
              }
              .btn_reject {
                background: #fc3333;
                .icon {
                  -webkit-mask-image: url('../../assets/icons/Close.svg');
                  mask-image: url('../../assets/icons/Close.svg');
                  width: 10px;
                  height: 10px;
                }
              }
              .btn_accept {
                margin-left: 10px;
                background: #41ca00;
                .icon {
                  -webkit-mask-image: url('../../assets/icons/Checkmark_White.svg');
                  mask-image: url('../../assets/icons/Checkmark_White.svg');
                  width: 16px;
                  height: 10px;
                }
              }
            }
          }
        }
      }
    }
    .pagination_wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      @media screen and (max-width: 767px) {
        flex-wrap: wrap;
      }
      & > span {
        font-weight: 400;
        font-size: 14px;
        color: #252830;
        @media screen and (max-width: 767px) {
          min-width: 100%;
        }
      }
      & > div {
        display: flex;
        align-items: center;
        @media screen and (max-width: 767px) {
          margin-top: 10px;
          justify-content: space-between;
          width: 100%;
        }
        & > ul {
          margin: 0 16px 0 0;
          li {
            &:not(:last-child) {
              margin-right: 8px;
            }
            a {
              border: 1px solid #d9d9d9;
              border-radius: 2px;
              background: #ffffff;
              width: 32px;
              height: 32px;
              color: #252830;
              font-weight: 400;
              font-size: 14px;
              &:focus {
                box-shadow: none;
              }
            }
          }
        }
        & > span {
          button {
            background: #ffffff;
            border: 1px solid #d9d9d9;
            border-radius: 2px;
            height: 32px;
            padding: 4px 12px;
            color: #252830;
            font-weight: 400;
            font-size: 14px;
            display: flex;
            &:focus {
              box-shadow: none;
            }
            &::after {
              content: '';
              position: relative;
              right: 0px;
              bottom: 4px;
              border: none;
              width: 10px;
              height: 30px;
              background-image: url('./../../assets//icons/ArrowDown.svg');
              background-position: center;
              background-repeat: no-repeat;
              background-size: 10px;
            }
          }
          & > ul {
            right: 0;
            bottom: 32px;
            min-width: 50px;
            li {
              a {
                text-decoration: none;
                font-weight: 400;
                font-size: 14px;
                color: #252830;
              }
            }
          }
        }
      }
    }
  }
}

.accepted {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #00d015;
  display: flex;
  align-items: center;
  span {
    background: #00d015;
    border-radius: 50%;
    width: 8px;
    height: 8px;
    margin-right: 8px;
  }
}

.rejected {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #ff0000;
  display: flex;
  align-items: center;
  span {
    background: #ff0000;
    border-radius: 50%;
    width: 8px;
    height: 8px;
    margin-right: 8px;
  }
}

.accept_btn_style {
  background: #00d015 !important;
  border-radius: 4px !important;
  padding: 10px;
  .icon {
    -webkit-mask-image: url('../../assets/icons/Checkmark_White.svg');
    mask-image: url('../../assets/icons/Checkmark_White.svg');
    width: 16px;
    height: 10px;
    -webkit-mask-size: contain;
    mask-size: contain;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    background: #ffffff;
    margin-right: 5px;
    vertical-align: middle;
    display: inline-block;
  }
}

.refuse_btn_style {
  background: #fc3333 !important;
  border-radius: 4px !important;
  padding: 10px;
  .icon {
    -webkit-mask-image: url('../../assets/icons/Close.svg');
    mask-image: url('../../assets/icons/Close.svg');
    width: 10px;
    height: 10px;
    -webkit-mask-size: contain;
    mask-size: contain;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    background: #ffffff;
    margin-right: 5px;
    vertical-align: middle;
    display: inline-block;
  }
}

.separator {
  & > :first-child {
    margin-right: 5px;
  }
}
