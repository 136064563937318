.Container {
  border: 1px solid var(--outline-scale-100);
  overflow: hidden;
  border-radius: 6px;
  height: 40px;
  min-width: 150px;
  display: flex;
  align-items: center;
  padding: 0 0.5rem;
  cursor: pointer;
  margin-bottom: 1rem;

  &.Error {
    border: 1px solid var(--error);
    background-color: var(--error-dimmed);
  }

  &.hasError {
    border-color: #ff0000 !important;
    &:focus,
    &:active {
      border-color: red !important;
    }
  }
}

.Input {
  background-color: transparent;
}

.Label {
  flex: 1;
  font-size: 14px;

  .RequiredIndicator {
    color: var(--accent);
    font-weight: bold;
  }
}

.CalendarContainer {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  box-sizing: border-box;
  width: 300px;
  height: 375px;
  margin-left: -8px;
  background-color: var(--white);
  border-radius: 4px;
  box-shadow: 0px 30px 84px rgba(19, 10, 46, 0.08), 0px 8px 32px rgba(19, 10, 46, 0.07),
    0px 3px 14px rgba(19, 10, 46, 0.03), 0px 1px 3px rgba(19, 10, 46, 0.13);
}

.Placeholder {
  color: var(--primary-scale-90);
}

.ErrorText {
  font-size: 0.75rem;
  color: var(--error) !important;
  position: absolute;
  bottom: -1rem;
}

.MainContainer {
  position: relative;
}
