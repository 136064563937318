@import "/src/styles/core";

.SideMenuContainer {
    position: fixed;
    z-index: 999;
    // width: 100%;
    height: 100%;

    .Overlay {
        position: fixed;
        width: 100%;
        height: 100%;
        z-index: 9999;
        background-color: #00000057;
        animation: fade-overlay 250ms ease-in forwards;
    }

    .Sidemenu {
        position: absolute;
        z-index: 9999;
        background-color: var(--white);
        height: 100%;
        width: 340px;
        transition: transform 250ms ease-in-out;
        border-right: 1px solid var(--outline-scale-80);
        overflow-y: auto;
        overscroll-behavior: contain;
    }

    &.Hidden {
        .Sidemenu {
            transform: translateX(-100%);
        }

        .Overlay {
            display: none;
        }
    }

    .LogoContainer {
        height: 80px;
        width: 100%;
        display: flex;
        align-items: center;
        padding: 0.5rem 0.5rem 0.5rem 2rem;
        border-bottom: 1px solid var(--outline-scale-80);

        .Logo {
            flex: 1;
            text-align: left;
            height: 100%;

            img {
                height: 100%;
            }
        }
    }
}

@keyframes fade-overlay {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@include media-breakpoint-up(xl) {
    .SideMenuContainer {
        .Sidemenu {
            transform: translateX(0);
        }

        &.Hidden {
            .Sidemenu {
                transform: translateX(0);
            }

            .Overlay {
                display: none;
            }
        }

        .Overlay {
            display: none;
        }
    }
}
