.input_wrapper {
  &.has_error {
    input {
      border-color: #ff0000 !important;
      &:focus,
      &:active {
        border-color: red !important;
      }
    }
  }
  label {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #404040;
    padding-bottom: 5px;
  }
  input {
    width: 100%;
    background: #ffffff;
    border: 1px solid #dfdfdf;
    border-radius: 6px;
    padding: 8px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #323232;
    &:focus,
    &:active {
      border-color: #dfdfdf !important;
    }
  }
  .error {
    color: #ff0000;
    font-weight: 400;
    font-size: 12px;
    height: 18px;
    margin-left: 24px;
  }
}
