.header_container {
  display: grid;
  grid-template-columns: max-content auto max-content;
  align-items: center;
  height: 10%;
  @media screen and (max-width: 767px) {
    grid-template-columns: 100%;
    row-gap: 10px;
  }
  .title {
    font-size: 18px;
    font-weight: 700;
    color: var(--primary-scale-100);
    margin-right: 18px;
    @media screen and (max-width: 767px) {
      margin-right: 0px;
    }
  }
  .search_bar {
    height: 44px;
    background-color: var(--outline-scale-70);
    border: 1px solid var(--outline-scale-100);
    border-radius: 4px;
    display: flex;
    align-items: center;
    max-width: 452px;
    padding: 12px 18px;

    .icon {
      width: 19px;
      height: 20px;
    }

    input {
      border: none;
      width: 100%;
      height: 19px;
      background-color: transparent;
      padding-left: 10px;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      color: #828282;
    }

    @media screen and (max-width: 767px) {
      max-width: calc(100% - 2rem);
      margin: 0 1rem;
    }
  }
  .actions {
    display: flex;
    height: 32px;
    font-style: normal;
    font-size: 14px;
    color: var(--primary-scale-100);
    @media screen and (max-width: 767px) {
      justify-content: center;
    }
    & > * {
      margin-left: 0.5rem;
    }
    .download_list {
      height: 32px;
      outline: none;
      border: 1px solid var(--outline-scale-100);
      background-color: transparent;
      display: flex;
      align-items: center;
      padding: 4px 8px;
      border-radius: 2px;
      @media screen and (max-width: 767px) {
        margin-left: 0;
      }
      span {
        margin-left: 0.5rem;
        font-weight: 400;
        color: #252830;
      }
    }
    .date_container {
      position: relative;
      .date_wrapper {
        background: #ffffff;
        border: 1px solid #d9d9d9;
        border-radius: 2px;
        padding: 4px 8px;
        font-style: normal;
        font-size: 14px;
        display: flex;
        align-items: center;
        height: 32px;
        &:focus {
          box-shadow: none;
        }
        &:focus-visible {
          outline: none;
        }
        img {
          margin-right: 10px;
          &:last-of-type {
            margin-left: 10px;
          }
        }
        span {
          font-weight: 400;
          color: #252830;
        }
      }
    }
    .dropdown_filter_wrapper {
      .button {
        background: #ffffff;
        border: 1px solid #d9d9d9;
        border-radius: 2px;
        padding: 4px 8px;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        color: #252830;
        display: flex;
        align-items: center;
        height: 32px;
        max-width: 350px;
        overflow: hidden;
        &::after {
          border: none;
          mask-image: url('../../../assets/icons/CaretDownAccent.svg');
          mask-repeat: no-repeat;
          mask-size: 10px;
          mask-position: center;
          background: rgba(0, 0, 0, 0.25);
          width: 10px;
          height: 6px;
          margin-left: 10px;
        }
        &:focus {
          box-shadow: none;
        }
        &:focus-visible {
          outline: none;
        }
        img {
          margin-right: 10px;
        }
      }
      .filter_menu {
        transform: translate(0px, 33px) !important;
        border: 1px solid #e0e0e0;
        border-radius: 0;
        margin-top: 10px;
        width: 80vw;
        max-width: 426px;
        padding: 20px 24px 23px;
        @media screen and (max-width: 767px) {
          width: 380px;
        }
        @media screen and (max-width: 500px) {
          width: calc(100vw - 6rem);
        }
        @media screen and (max-width: 400px) {
          width: 90vw;
        }
        &::after {
          content: '';
          position: absolute;
          width: 10px;
          height: 10px;
          background: #ffffff;
          transform: rotate(45deg);
          top: -5px;
          right: 60px;
          box-shadow: -1px -1px 0px 0px #e0e0e0;
        }
        .filter_input {
          margin: 0 !important;
          width: 100%;
          border: 1px solid #e0e0e0;
          border-radius: 4px;
          background: url('../../../assets/icons/Search.svg') no-repeat 10px 12px #fafafa;
          background-size: 17px;
          height: 44px;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          color: #828282;
          padding: 13px 13px 13px 35px;
          &:focus {
            border-color: #e0e0e0 !important;
          }
          &:disabled {
            opacity: 0.5;
          }
        }
        ul {
          margin-top: 15px;
          margin-bottom: 0;
          max-height: 440px;
          overflow-x: auto;
          .option {
            white-space: normal;
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            color: #252830;
            padding: 6px;
            border-radius: 5px;
            &:not(:first-of-type) {
              margin-top: 8px;
            }
            &:hover {
              background: #0d6efd;
              color: white;
            }
          }
        }
      }
    }
  }
}
