.Container {
    display: flex;
    align-items: center;
    padding: 1.5rem 0;
    border-bottom: 1px solid var(--outline-scale-100);

    .Avatar {
        width: 55px;
        height: 55px;
    }

    .ChatDetails {
        flex: 1;
        padding-left: 0.5rem;

        .Sender {
            font-size: 14px;
            font-weight: 400;
            color: var(--primary-scale-90);
        }

        .LastMessage {
            color: var(--primary-scale-80);
            font-size: 14px;
        }
    }
}
