@import "/src/styles/core";

.Container {
    grid-area: chat-items;

    .List {
        display: flex;
        gap: 14px;
        padding: 0 1rem 1rem 1rem;
        overflow: auto;
        @media screen and (max-width: 767px) {
            overflow-y: auto;
            display: block;
            max-height: 150px;
        }
        .loading {
            text-align: center;
        }
        .no_messages {
            text-align: center;
            font-size: 14px;
            font-weight: 400;
        }
    }
}

@include media-breakpoint-up(md) {
    .Container {
        border-right: 1px solid var(--outline-scale-100);
        background: #FFFFFF;

        .List {
            display: block;
            width: 320px;
            height: calc(100vh - 98px);
            padding: 2px 6px 0 0;
        }
    }
}
