.Switch {
    display: inline-block;
    margin: 0.5rem 0;

    // konkretno za Cena Bez Limit pri kreiranje oglas
    &.Start {
        display: block !important;
        margin: 0;
    }

    label {
        font-size: 14px !important;
        color: var(--primary-scale-90);
        display: flex;
        align-items: center;
        position: relative;
        cursor: pointer;

        &::after {
            content: "";
            width: 1.75em;
            height: 0.5em;
            background-color: var(--outline-scale-100);
            border-radius: 1em;
            margin-left: 1em;
            margin-top: -3px;
        }

        &::before {
            content: "";
            width: 1.1em;
            height: 1.1em;
            background-color: var(--primary-scale-80);
            border-radius: 1em;
            margin-left: 1em;
            margin-top: -3px;
            position: absolute;
            right: 0.7em;
            transition: right 200ms ease-in-out;
        }
    }

    input[type="checkbox"] {
        opacity: 0;
        position: absolute;

        &:checked {
            ~ label {
                &::before {
                    right: 0em;
                    background-color: var(--accent);
                }
            }
        }
    }
}

.Switch {
    &.Start {
        label {
            padding-left: 2.5rem;

            &::before {
                position: absolute;
                left: -5px;
                top: 5px;
                z-index: 1;
                transition: left 200ms ease-in-out;
            }

            &::after {
                position: absolute;
                left: -5px;
                top: 9px;
            }
        }

        input[type="checkbox"] {
            opacity: 0;
            position: absolute;

            &:checked {
                ~ label {
                    &::before {
                        left: 5px;
                        background-color: var(--accent);
                    }
                }
            }
        }
    }
}


.switch_container {
    display: inline-block;
    margin: 25px 0 0.5rem;
    &.Start {
        .switch_wrapper {
            flex-direction: row-reverse;
            .switch {
                margin-left: 3px;
                margin-right: 8px;
            }
        }
    }
    .switch_wrapper {
        font-size: 14px !important;
        color: var(--primary-scale-90);
        display: flex;
        align-items: center;
        .switch {
            position: relative;
            display: inline-block;
            width: 1.75em;
            height: 0.5em;
            overflow: visible;
            margin-left: 15px;
            input[type="checkbox"] {
                opacity: 0;
                width: 0;
                height: 0;
                &:checked + .slider {
                    background-color: rgba(33, 150, 243, 0.16);
                    &:before {
                        -webkit-transform: translateX(13px);
                        -ms-transform: translateX(13px);
                        transform: translateX(13px);
                        background-color: #0044BB;
                    }
                }
            }
            .slider {
                position: absolute;
                cursor: pointer;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background-color: var(--outline-scale-100);
                -webkit-transition: .4s;
                transition: .4s;
                border-radius: 34px;
                &:before {
                    position: absolute;
                    content: "";
                    width: 1.1em;
                    height: 1.1em;
                    left: -2px;
                    top: -4px;
                    background-color: var(--primary-scale-80);
                    -webkit-transition: .4s;
                    transition: .4s;
                    border-radius: 50%;
                }
            }
        }
    }
}