.container {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  display: flex;
  align-items: center;

  span {
    border-radius: 50%;
    width: 8px;
    height: 8px;
    margin-right: 8px;
    background: currentColor;
  }
}

.pending {
  color: #e9db16;
}

.accepted {
  color: #00d015;
}

.rejected {
  color: #ff0000;
}
