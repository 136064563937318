@import "/src/styles/core";

.Container {
    .Title {
        font-size: 18px;
        font-weight: 700;
        color: var(--primary-scale-100);
    }

    .SearchBar {
        height: 44px;
        background-color: var(--outline-scale-70);
        border: 1px solid var(--outline-scale-100);
        border-radius: 4px;
        display: flex;
        align-items: center;
        padding: 0 1rem;
        @media screen and (max-width: 991px) {
            margin-top: 10px
        }

        .Icon {
            width: 24px;
            height: 24px;
        }

        input {
            border: none;
            width: 100%;
            height: 100%;
            background-color: transparent;
            padding-left: 1rem;
        }
    }

    .Filters {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        padding: 0.5rem;

        select {
            height: 2rem;
            padding: 0.35rem;
            border-color: #d9d9d9;
            border-radius: 2px;
            color: #252830;
        }
    }
}

@include media-breakpoint-up(md) {
    .Container {
        .Filters {
            justify-content: end;

            .FilterList {
                margin-left: 8px;
            }
        }
    }
}
