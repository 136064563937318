.MenuItems {
    ul {
        margin: 0;
        padding: 0;

        li {
            display: flex;
            align-items: center;
            cursor: pointer;
            border-bottom: 1px solid var(--outline-scale-80);

            a {
                height: 55px;
                padding: 0.5rem 0.5rem 0.5rem 1.5rem;
                display: flex !important;
                align-items: center;
                width: 100%;
                text-decoration: none;
                position: relative;

                &.ActiveLink {
                    background-color: var(--background-accent);

                    &::after {
                        content: "";
                        position: absolute;
                        height: 10px;
                        width: 2px;
                        background-color: var(--accent);
                        right: 0;
                        height: 70%;
                        width: 4px;
                        border-top-left-radius: 4px;
                        border-bottom-left-radius: 4px;
                    }
                }
            }

            &:first-child {
                border-top: 1px solid var(--outline-scale-80);
            }

            // &:hover {
            //     background-color: var(--accent-hover);
            // }

            .MenuIcon {
                width: 24px;
                height: 24px;
                text-align: center;
                margin-right: 1rem;
            }

            .MenuIconIdicator {
                width: 24px;
                height: 24px;
                text-align: center;
            }

            .MenuTitle {
                color: var(--black);
                flex: 1;
            }
        }
    }
}

.LogoutButton {
    height: 55px;
    padding: 0.5rem 0.5rem 0.5rem 1.5rem;
    display: flex !important;
    align-items: center;
    width: 100%;
    text-decoration: none;
    position: relative;
}

.Counter {
    color: var(--accent);
    font-weight: 500;
}
