.overlay_style {
  z-index: 9999;
  .modal_style {
    width: 1141px !important;
    max-width: 100%;
    & > div:first-of-type {
      height: 73px;
      min-height: 73px;
      border: none;
      & > div:first-of-type {
        display: none;
      }
      & > div:last-of-type {
        margin: 0;
        padding: 8px 20px;
        text-align: center;
        flex-direction: row;
        justify-content: space-between;
        h2 {
          max-width: unset;
          font-style: normal;
          font-weight: 500;
          font-size: 24px;
          color: #323232;
          display: flex;
          align-items: center;
        }
      }
      .footer_wrapper {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        @media screen and (max-width: 399px) {
          flex-direction: column;
          justify-content: center;
          row-gap: 10px;
          padding: 0 24px;
          & > div {
            width: 100% !important;
            margin: 0 !important;
          }
        }
        .close_btn {
          width: 84px;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          color: #0044bb;
          cursor: pointer;
          text-align: center;
          padding: 12px 0px;
          margin-right: 25px;
        }
        .submit_btn {
          padding: 12px 50px;
          background: #0044bb;
          border-radius: 6px;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          color: #fbfbfb;
          cursor: pointer;
          text-align: center;
        }
      }
    }
    & > div:last-of-type {
      padding: 0;
      .body_wrapper {
        padding: 24px 0 0 24px;
        @media screen and (max-width: 1139px) {
          grid-template-columns: 100%;
          padding-right: 24px;
        }
        .products_wrapper {
          .product_wrapper {
            display: grid;
            grid-template-columns: 37px auto;
            .checkbox_wrapper {
              label {
                display: block;
                position: relative;
                cursor: pointer;
                -webkit-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                user-select: none;
                input {
                  opacity: 0;
                  cursor: pointer;
                  &:checked ~ .checkmark {
                    background-color: #0044bb;
                    &:after {
                      display: block;
                    }
                  }
                  &:disabled ~ .checkmark {
                    background-color: #e3e3e3 !important;
                  }
                }
                .checkmark {
                  position: absolute;
                  top: 0;
                  left: 0;
                  height: 24px;
                  width: 24px;
                  border-radius: 2.9px;
                  border: 1px solid #d9d9d9;
                  &:after {
                    content: '';
                    position: absolute;
                    display: none;
                    left: 9px;
                    top: 5px;
                    width: 5px;
                    height: 10px;
                    border: solid #ffffff;
                    border-width: 0 3px 3px 0;
                    -webkit-transform: rotate(45deg);
                    -ms-transform: rotate(45deg);
                    transform: rotate(45deg);
                  }
                }
              }
            }
            .product {
              margin-right: 16px;
              padding-bottom: 12px;
              margin-bottom: 12px;
              border-bottom: 1px solid #dfdfdf;
              display: flex;
              justify-content: space-between;

              @media screen and (max-width: 1139px) {
                margin-right: 0;
              }
              .product_details_split,
              .product_details_split_2 {
                display: flex;
                justify-content: space-between;
                .product_details_inner {
                  .title {
                    font-style: normal;
                    font-weight: 600;
                    font-size: 18px;
                    color: #404040;
                  }
                  .category {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    color: #606060;
                    margin-top: 5px;
                  }
                  .description {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    color: #323232;
                    margin-top: 4px;
                  }
                }
                .quamtity_wrapper {
                  display: flex;
                  align-items: center;
                  margin-right: 12px;
                }
              }
              .label {
                margin-top: 15px;
                margin-right: 8px;
                font-style: normal;
                font-weight: 600;
                font-size: 18px;
                color: #404040;
                line-height: normal;
              }
              .value {
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                color: #606060;
              }

              .product_details_split_2 {
                margin-top: 18px;
              }

              .quantity_price {
                margin: 15px 0;
                display: flex;
                justify-content: space-between;

                @media screen and (max-width: 630px) {
                  flex-direction: column;
                  row-gap: 10px;
                }

                .price_wrapper {
                  display: flex;
                  align-items: center;
                  @media screen and (max-width: 399px) {
                    flex-wrap: wrap;
                    row-gap: 10px;
                  }
                  .label {
                    font-style: normal;
                    font-weight: 600;
                    font-size: 18px;
                    color: #404040;
                    line-height: normal;
                    @media screen and (max-width: 399px) {
                      flex: 0 1 100%;
                    }
                  }
                  .value {
                    margin-left: 12px;
                    @media screen and (max-width: 399px) {
                      margin-left: 0;
                    }
                    .error_text {
                      position: absolute;
                    }
                    &.has_error {
                      input {
                        border-color: #ff0000 !important;
                      }
                      .error_text {
                        color: #ff0000;
                      }
                    }
                    input {
                      background: #ffffff;
                      border: 1px solid #d3d3d3;
                      border-radius: 6px;
                      padding: 8px;
                      font-style: normal;
                      font-weight: 400;
                      font-size: 16px;
                      color: #323232;
                      max-width: 130px;
                      &:focus,
                      &:active {
                        border-color: #d3d3d3 !important;
                      }
                      &:focus-visible {
                        outline: none;
                      }
                      &:disabled {
                        background-color: #e3e3e3;
                      }
                      @media screen and (max-width: 399px) {
                        max-width: unset;
                      }
                    }
                  }
                  .currency {
                    margin-left: 12px;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    color: #808080;
                  }
                }
              }
            }
          }
        }
        .product_details {
          width: 25%;
        }
        .additional_info_wrapper {
          border-left: 1px solid #dfdfdf;
          width: 75%;
          @media screen and (max-width: 1139px) {
            border: none;
          }
          .label {
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            color: #808080;
            background: #f8f8f8;
            padding: 8px 24px;
          }
          .add_offer {
            display: flex;
            justify-content: flex-end;
            .close_btn {
              font-style: normal;
              font-weight: 500;
              font-size: 16px;
              color: #0044bb;
              cursor: pointer;
              text-align: center;
              padding: 6px 0px;
              margin-right: 25px;
            }
          }
          .additional_docs_wrapper {
            .label {
              font-style: normal;
              font-weight: 700;
              font-size: 18px;
              color: #808080;
              background: #f8f8f8;
              padding: 8px 24px;
            }
            label {
              margin-left: 24px;
              margin-top: 14px;
              font-style: normal;
              font-weight: 600;
              font-size: 16px;
              color: #0044bb;
              cursor: pointer;
              &.disabled {
                color: #e3e3e3;
              }
              img {
                width: 18px;
                margin-right: 10px;
              }
            }
            .docs_wrapper {
              display: flex;
              flex-wrap: wrap;
              column-gap: 20px;
              margin: 12px 12px 12px 24px;
              row-gap: 12px;
              .doc_wrapper {
                flex: 0 1 auto;
                display: flex;
                align-items: center;
                padding: 8px;
                background: #ebf2ff;
                border: 1px solid #0044bb;
                border-radius: 6px;
                cursor: pointer;
                .file_icon {
                  margin-right: 10px;
                }
                .file_name {
                  font-style: normal;
                  font-weight: 400;
                  font-size: 16px;
                  color: #0044bb;
                }
                .close_icon {
                  cursor: pointer;
                  margin-left: 10px;
                  background: rgba(214, 225, 244, 0.84);
                  border: 1px solid #0044bb;
                  border-radius: 5px;
                  padding: 3px;
                  width: 16px;
                }
              }
            }
          }
        }
        .comment_wrapper {
          .label {
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            color: #808080;
            background: #f8f8f8;
            padding: 8px 24px;
          }
          label {
            margin: 12px 0 0 24px;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            color: #404040;
          }
          textarea {
            margin: 0 12px 0 24px;
            width: calc(100% - 36px);
            resize: none;
            background: #ffffff;
            border: 1px solid #dfdfdf;
            border-radius: 6px;
            padding: 8px;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            color: #323232;
            &:focus,
            &:active {
              border-color: #dfdfdf !important;
            }
            &:focus-visible {
              outline: none;
            }
            &:disabled {
              background-color: #e3e3e3;
            }
            @media screen and (max-width: 1139px) {
              margin-right: 24px;
              width: calc(100% - 48px);
            }
          }
        }
      }
      .footer_wrapper {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        height: 92px;
        padding-right: 32px;
        margin-bottom: 24px;
        @media screen and (max-width: 399px) {
          flex-direction: column;
          justify-content: center;
          row-gap: 10px;
          padding: 0 24px;
          & > div {
            width: 100% !important;
            margin: 0 !important;
          }
        }
        .close_btn {
          width: 84px;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          color: #0044bb;
          cursor: pointer;
          text-align: center;
          padding: 12px 0px;
          margin-right: 25px;
        }
        .submit_btn {
          padding: 12px 50px;
          background: #0044bb;
          border-radius: 6px;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          color: #fbfbfb;
          cursor: pointer;
          text-align: center;
        }
      }
    }
    .split_form {
      display: flex;
      & > .split_form_inner {
        width: 100%;
        &:first-child {
          margin-right: 10px;
        }
      }
    }
  }
  .space {
    margin: 0 20px;
    margin-top: 15px;
  }
}
