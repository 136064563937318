.header_content_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  top: 20px;
  @media screen and (max-width: 767px) {
    display: block;
  }
  .back_btn {
    background: #ffffff;
    border-radius: 16px;
    height: 30px;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    text-decoration-line: underline;
    color: #0044bb;
    border: none;
    padding: 4px 10px;
    text-underline-offset: 3px;
  }
  .info {
    background: #ffffff;
    border-left: 5px solid #41ca00;
    box-shadow: 0px 33px 66px -12px rgba(121, 170, 255, 0.22);
    border-radius: 5px;
    padding: 16px 32px;
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: normal;
    color: #11ac05;
    @media screen and (max-width: 767px) {
      position: fixed;
      bottom: 20px;
      left: 12px;
      right: 12px;
    }
    .image_wrapper {
      margin-right: 10px;
      min-width: 32px;
      min-height: 32px;
      width: 32px;
      height: 32px;
      background: #41ca00;
      border-radius: 50%;
      padding: 7px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}

.ad_preview_wrapper {
  padding: 13px 25px;
  margin-bottom: 50px;
  @media screen and (max-width: 469px) {
    padding-bottom: 60px;
  }
  .contact_info_wrapper {
    padding: 12px 0;
    border-bottom: 2px solid #dfdfdf;
    .title {
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      color: #404040;
    }
    .info_wrapper {
      display: grid;
      grid-template-columns: repeat(3, 33.333%);
      @media screen and (max-width: 767px) {
        margin-top: 8px;
        grid-template-columns: 100%;
        row-gap: 5px;
      }
      .name,
      .email,
      .phone {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        color: #606060;
      }
      .phone {
        text-align: right;
        @media screen and (max-width: 767px) {
          text-align: left;
        }
      }
    }
  }
  .ads_wrapper {
    margin-top: 24px;
    .title {
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      color: #404040;
    }
  }
  .time_criteria_wrapper {
    padding: 12px 0;
    border-bottom: 2px solid #dfdfdf;
    .title {
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      color: #404040;
    }
    .content_wrapper {
      display: grid;
      grid-template-columns: minmax(172px, max-content) max-content;
      column-gap: 15px;
      @media screen and (max-width: 767px) {
        margin-top: 8px;
        grid-template-columns: 100%;
        row-gap: 10px;
      }
      .date_wrapper {
        font-style: normal;
        font-size: 16px;
        color: #606060;
        .title {
          font-weight: 400;
        }
        .date {
          font-weight: 600;
        }
      }
      .criteria_wrapper {
        font-style: normal;
        font-size: 16px;
        color: #606060;
        .title {
          font-weight: 400;
        }
        .criteria {
          font-weight: 600;
        }
      }
    }
  }
  .auction_wrapper {
    padding: 12px 0;
    border-bottom: 2px solid #dfdfdf;
    .title {
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      color: #404040;
    }
    .content_wrapper {
      display: grid;
      grid-template-columns: minmax(172px, max-content) max-content;
      column-gap: 15px;
      @media screen and (max-width: 767px) {
        margin-top: 8px;
        grid-template-columns: 100%;
        row-gap: 10px;
      }
      .auction_container {
        font-style: normal;
        font-size: 16px;
        color: #606060;
        .title {
          font-weight: 400;
        }
        .on_auction {
          font-weight: 600;
        }
      }
      .delivery_deadline_wrapper {
        font-style: normal;
        font-size: 16px;
        color: #606060;
        .title {
          font-weight: 400;
        }
        .date_time {
          font-weight: 600;
          .vertical_line {
            margin: 0 5px;
          }
        }
      }
    }
  }
  .admin_info_wrapper {
    padding: 12px 0;
    border-bottom: 2px solid #dfdfdf;
    .title {
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      color: #404040;
    }
    .content_wrapper {
      display: grid;
      grid-template-columns: minmax(172px, max-content) minmax(calc((100% / 3) - 172px), max-content) minmax(
          calc((100% / 3) - 172px),
          max-content
        );
      column-gap: 15px;
      @media screen and (max-width: 991px) {
        margin-top: 8px;
        grid-template-columns: 100%;
        row-gap: 10px;
      }
      .info_container {
        font-style: normal;
        font-size: 16px;
        color: #606060;
        .title {
          font-weight: 400;
        }
        .date_time {
          font-weight: 600;
          .vertical_line {
            margin: 0 5px;
          }
        }
      }
      .questions_wrapper {
        font-style: normal;
        font-size: 16px;
        color: #606060;
        .title {
          font-weight: 400;
        }
        .date_time {
          font-weight: 600;
          .vertical_line {
            margin: 0 5px;
          }
        }
      }
      .answers_wrapper {
        font-style: normal;
        font-size: 16px;
        color: #606060;
        .title {
          font-weight: 400;
        }
        .date_time {
          font-weight: 600;
          .vertical_line {
            margin: 0 5px;
          }
        }
      }
    }
  }
  .tehnical_info_wrapper {
    padding: 12px 0;
    border-bottom: 2px solid #dfdfdf;
    .title {
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      color: #404040;
    }
    .doc_wrapper {
      margin-top: 8px;
      display: inline-flex;
      background: #ebf2ff;
      border: 1px solid #0044bb;
      border-radius: 6px;
      padding: 16px 8px;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      color: #0044bb;
      .file_name {
        cursor: pointer;
      }
      .icon {
        width: 24px;
        height: 24px;
        -webkit-mask-image: url('../../assets/icons/FileText.svg');
        mask-image: url('../../assets/icons/FileText.svg');
        -webkit-mask-size: contain;
        mask-size: contain;
        -webkit-mask-repeat: no-repeat;
        mask-repeat: no-repeat;
        background: #0044bb;
        margin-right: 8px;
      }
    }
  }
}

.submit_offer {
  position: fixed;
  column-gap: 90px;
  bottom: 0;
  left: 340px;
  right: 0;
  width: calc(100% - 340px);
  padding: 16px 0px 16px 1px;
  background: #0044bb;
  border: 1px solid #0044bb;
  box-shadow: 0px 33px 66px -12px rgba(121, 170, 255, 0.22);
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 1199px) {
    width: 100% !important;
    left: 0;
  }
  @media screen and (max-width: 469px) {
    flex-direction: column-reverse;
    row-gap: 10px;
    text-align: center;
    & > div {
      width: 220px;
    }
  }
  .cancel_btn {
    border-radius: 6px;
    padding: 8px 42px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #ffffff;
    cursor: pointer;
  }
  .submit_btn {
    background: #ffffff;
    border-radius: 6px;
    padding: 8px 42px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #0044bb;
    cursor: pointer;
  }
}
