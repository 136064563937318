@import "/src/styles/core";

.Container {
    display: flex;
    align-items: center;
    cursor: pointer;
    &:last-of-type {
        border-bottom: 1px solid var(--outline-scale-100);
    }

    @media screen and (max-width: 767px) {
        padding: 10px;
    }

    &.selected_chat {
        background: #F5F7F6;
        .ChatSummary {
            .Sender {
                font-weight: normal;
            }
            .LastMessage {
                p {
                    color: #323232;
                }
            }
        }
    }

    &.NotRead {
        background-color: var(--outline-scale-90);

        .ChatSummary {
            color: var(--primary-scale-90) !important;

            .Sender {
                font-weight: 500;
            }

            .LastMessage {
                 p {
                    font-weight: 500 !important;
                    color: var(--primary-scale-90) !important;
                 }
            }
        }
    }

    .Avatar {
        width: 40px;
        height: 40px;
    }

    .ChatSummary {
        flex: 1;
        padding: 0 13px 0 16px;

        .Sender {
            font-size: 14px;
            font-weight: 400;
            color: #323232;
        }

        .LastMessage {
            p {
                margin: 0 !important;
                color: var(--primary-scale-80);
                font-size: 14px;
                font-weight: 400;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: 200px;

                &:not(:first-child) {
                    display: none !important;
                }
            }
        }
    }

    .ChatTime {
        font-size: 12px;
        align-self: baseline;
        color: #323232;
        font-weight: 400;
    }
}

@include media-breakpoint-up(md) {
    .Container {
        padding: 1rem 0.5rem;
        border-top: 1px solid var(--outline-scale-100);
    }
}
