@import "/src/styles/core";

.LoginForm {
    padding: 3rem 1rem;
    position: relative;
    z-index: 10;
    background-color: var(--primary-scale-70);

    .NotRegistered {
        a {
            text-decoration: none;
            border-bottom: 1px solid var(--accent);
        }
    }
}

.Separator {
    .Line {
        border: 1px solid var(--outline-scale-80);
        height: 2px;
    }

    .Text {
        width: 40px;
        height: 40px;
        border: 2px solid var(--outline-scale-80);
        border-radius: 50%;
        font-size: 14px;
    }
}

.LoginLogo {
    margin-top: 4rem;
    img {
        position: absolute;
        right: 0;
        // bottom: 10rem;
        width: 50%;
    }
}

.DisabledLink {
    pointer-events: none;
    cursor: not-allowed;
}

.PointerDefault {
    cursor: default;
}

.Pointer {
    cursor: pointer;
}

.FailedLogin {
    font-size: 0.9rem;
    padding: 0.5rem 0 1rem 0;
}

.InfoLogin {
    font-size: 0.9rem;
    padding: 0.5rem 0 1rem 0;
}

.ResetButtons {
    display: flex;
    gap: 0.5rem;
    margin-top: 1rem;
}

// @include media-breakpoint-up(md) {
//     .LoginContainer {
//         grid-auto-columns: 60% 40%;
//     }
// }

@include media-breakpoint-up(lg) {
    .LoginLogo {
        margin-top: 4rem;
        img {
            position: absolute;
            right: 0;
            // bottom: 10rem;
            width: 40%;
        }
    }
}
