.modal_wrapper {
  .custom_header {
    background: #f2f2f2;
    border-bottom: none;
    display: flex;
    align-items: start;
    justify-content: center;
    & > div {
      flex: 1;
      text-align: center;
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      color: #323232;
    }
    & > button {
      padding: 10px;
      background-color: silver;
      border-radius: 50%;
      background-size: 8px;
      width: 28px;
      height: 28px;
      margin-right: 0;
      &:focus {
        box-shadow: none;
      }
      &:focus-visible {
        outline: none;
      }
    }
  }
  .custom_body {
    padding: 16px 18px;
    overflow-y: auto;
    .company_info_wrapper {
      display: grid;
      grid-template-columns: 144px auto 50px;
      column-gap: 12px;
      border-bottom: 1px solid #dfdfdf;
      padding-bottom: 15px;
      .image_wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        .image_container {
          width: 100px;
          height: 100px;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            max-width: 100px;
            max-height: 100px;
          }
        }
        .company_name {
          font-style: normal;
          font-weight: 700;
          font-size: 18px;
          color: #323232;
        }
      }
      .company_info {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        color: #606060;
        .company_name {
          font-weight: 600;
          color: #404040;
        }
        & > div {
          &:not(:last-of-type) {
            margin-bottom: 6px;
          }
        }
      }
      .message_icon {
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        .image_wrapper {
          border: 1px solid #0044bb;
          border-radius: 100%;
          width: 42px;
          height: 42px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          img {
            width: 18px;
            height: 16px;
          }
        }
      }
    }
    .contact_wrapper {
      border-bottom: 1px solid #dfdfdf;
      padding: 8px 0 12px;
      display: flex;
      gap: 60px;
      .title {
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        color: #404040;
      }
      .contact_info_wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 6px;
        & > div {
          font-style: normal;
          font-weight: 400;
          font-size: 15px;
          color: #606060;
        }
      }
      .phone {
        align-self: end;
        margin-left: auto;
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        color: #606060;
      }
    }
    .space {
      border-bottom: 1px solid #dfdfdf;
    }
    .comment_wrapper {
      padding: 12px 0;
      .title {
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        color: #404040;
        margin-bottom: 12px;
      }
      textarea {
        resize: none;
        width: 100%;
        // background: #f5f5f5;
        border: 1px solid #dfdfdf;
        border-radius: 6px;
        padding: 16px 12px;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        color: #323232;
        &:focus-visible {
          outline: none;
        }
      }
    }
    .additional_docs_wrapper {
      padding: 12px 0;
      border-bottom: 1px solid #dfdfdf;
      .label {
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        color: #808080;
        background: #f8f8f8;
        padding: 8px 24px;
      }
      label {
        margin-left: 24px;
        margin-top: 14px;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        color: #0044bb;
        cursor: pointer;
        &.disabled {
          color: #e3e3e3;
        }
        img {
          width: 18px;
          margin-right: 10px;
        }
      }
      .title {
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        color: #404040;
      }
      .docs_wrapper {
        margin-top: 12px;
        display: flex;
        flex-wrap: wrap;
        column-gap: 19px;
        row-gap: 12px;
        cursor: pointer;
        & > div {
          display: flex;
          align-items: center;
          background: #ebf2ff;
          border: 1px solid #0044bb;
          border-radius: 6px;
          padding: 16px 8px;
          .icon {
            margin-right: 10px;
            width: 24px;
            height: 24px;
            -webkit-mask-image: url('../../../../../assets/icons/FileText.svg');
            mask-image: url('../../../../../assets/icons/FileText.svg');
            -webkit-mask-size: contain;
            mask-size: contain;
            background: #0044bb;
          }
        }
        .close_icon {
          margin-left: 10px;
          background: rgba(214, 225, 244, 0.84);
          border: 1px solid #0044bb;
          border-radius: 5px;
          padding: 3px;
          width: 16px;
        }
      }
    }
  }
  .price_wrapper {
    display: flex;
    align-items: center;
    & > :nth-child(2) {
      margin-left: 10px;
      margin-top: 5px;
    }
  }

  .split_form {
    display: flex;
    justify-content: space-between;
    .split_form_inner {
      width: 32.333%;
    }
  }
}

.actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .price_wrapper {
    .label {
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      color: #404040;
    }
    .price {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      color: #323232;
    }
  }
  .buttons_wrapper {
    button {
      min-width: 150px;
      height: 44px;
      border: none;
      border-radius: 4px;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      color: #fbfbfb;
      padding: 10px;
      .icon {
        -webkit-mask-size: contain;
        mask-size: contain;
        -webkit-mask-repeat: no-repeat;
        mask-repeat: no-repeat;
        background: #ffffff;
        margin-right: 5px;
        vertical-align: middle;
        display: inline-block;
      }
      &.refuse_btn {
        margin-right: 10px;
        background: #fc3333;
        min-width: 50px;
        .icon {
          -webkit-mask-image: url('../../../../../assets/icons/Close.svg');
          mask-image: url('../../../../../assets/icons/Close.svg');
          width: 16px;
          height: 15px;
          margin-right: 0;
        }
      }
      &.accept_btn {
        background: #41ca00;
        min-width: 50px;
        .icon {
          -webkit-mask-image: url('../../../../../assets/icons/Checkmark_White.svg');
          mask-image: url('../../../../../assets/icons/Checkmark_White.svg');
          width: 18px;
          height: 14px;
          margin-right: 0;
        }
      }
      &.edit_btn {
        margin-right: 10px;
        background: #0044bb;
        min-width: 50px;

        .icon {
          -webkit-mask-image: url('../../../../../assets/icons/Pencil.svg');
          mask-image: url('../../../../../assets/icons/Pencil.svg');
          width: 21px;
          height: 23px;
        }
      }
      &.delete_btn {
        background: #fc3333;
        min-width: 50px;
        .icon {
          -webkit-mask-image: url('../../../../../assets/icons/TrashIcon.svg');
          mask-image: url('../../../../../assets/icons/TrashIcon.svg');
          width: 21px;
          height: 23px;
        }
      }
    }
  }
}

.table_wrapper {
  margin-top: 20px;
  margin-bottom: 10px;
  .table_container {
    margin-top: 15px;
    .offers_table {
      overflow-x: auto;
      display: block;
      width: 100%;
      white-space: nowrap;
      thead {
        border: transparent;
        tr {
          th {
            font-weight: 600;
            font-size: 14px;
            color: #252830;
            background: #fafafa;
            padding: 22px 8px;
            border: none;
            position: relative;
            height: 70px;
            text-align: center;
            &:not(:last-of-type) {
              &::after {
                content: '';
                border-right: 1px solid #ebebeb;
                position: absolute;
                right: 0;
                top: 12px;
                height: 46px;
              }
            }
            &:nth-child(1) {
              min-width: 200px;
              width: 200x;
            }
            &:nth-child(2) {
              min-width: 160px;
              width: 160px;
            }
            &:nth-child(3) {
              min-width: 150px;
              width: 150px;
            }
            &:nth-child(4) {
              min-width: 150px;
              width: 150px;
            }
            &:nth-child(5) {
              min-width: 150px;
              width: 150px;
            }
            &:nth-child(6) {
              min-width: 150px;
              width: 150px;
            }
            .sort_wrapper {
              display: grid;
              grid-auto-rows: max-content max-content;
              grid-row-gap: 2px;
              margin-left: 5px;
              .caret {
                display: inline-block;
                width: 0;
                height: 0;
                margin-left: 2px;
                vertical-align: middle;
                border-top: 4px dashed rgba(0, 0, 0, 0.25);
                border-top: 4px solid \9;
                border-right: 4px solid transparent;
                border-left: 4px solid transparent;
              }
              .caret_up {
                content: '';
                border-top: 0;
                border-bottom: 4px dashed rgba(0, 0, 0, 0.25);
                border-bottom: 4px solid \9;
              }
            }
          }
        }
      }
      tbody {
        border: none;
        tr {
          height: 50px;
          td {
            border: none;
            border-bottom: 1px solid #dfdfdf;
            vertical-align: middle;
            font-weight: 400;
            font-size: 14px;
            color: #252830;

            &:nth-child(2),
            &:nth-child(3),
            &:nth-child(6) {
              text-align: center;
            }

            &:nth-child(4),
            &:nth-child(5) {
              text-align: end;
            }

            &:last-of-type {
              button {
                border: none;
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: 14px;
                text-align: center;
                color: #ffffff;
                padding: 9px 15px;
                border-radius: 4px;
                &:focus-visible {
                  outline: none;
                }
                .icon {
                  -webkit-mask-size: contain;
                  mask-size: contain;
                  -webkit-mask-repeat: no-repeat;
                  mask-repeat: no-repeat;
                  background: #ffffff;
                  margin-right: 0;
                  vertical-align: middle;
                  display: inline-block;
                }
              }
              .btn_reject {
                background: #fc3333;
                .icon {
                  -webkit-mask-image: url('../../../../../assets/icons/Close.svg');
                  mask-image: url('../../../../../assets/icons/Close.svg');
                  width: 10px;
                  height: 10px;
                }
              }
              .btn_accept {
                margin-left: 10px;
                background: #41ca00;
                .icon {
                  -webkit-mask-image: url('../../../../../assets/icons/Checkmark_White.svg');
                  mask-image: url('../../../../../assets/icons/Checkmark_White.svg');
                  width: 16px;
                  height: 10px;
                }
              }
            }
          }
        }
      }
    }
    .pagination_wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      @media screen and (max-width: 767px) {
        flex-wrap: wrap;
      }
      & > span {
        font-weight: 400;
        font-size: 14px;
        color: #252830;
        @media screen and (max-width: 767px) {
          min-width: 100%;
        }
      }
      & > div {
        display: flex;
        align-items: center;
        @media screen and (max-width: 767px) {
          margin-top: 10px;
          justify-content: space-between;
          width: 100%;
        }
        & > ul {
          margin: 0 16px 0 0;
          li {
            &:not(:last-child) {
              margin-right: 8px;
            }
            a {
              border: 1px solid #d9d9d9;
              border-radius: 2px;
              background: #ffffff;
              width: 32px;
              height: 32px;
              color: #252830;
              font-weight: 400;
              font-size: 14px;
              &:focus {
                box-shadow: none;
              }
            }
          }
        }
        & > span {
          button {
            background: #ffffff;
            border: 1px solid #d9d9d9;
            border-radius: 2px;
            height: 32px;
            padding: 4px 12px;
            color: #252830;
            font-weight: 400;
            font-size: 14px;
            display: flex;
            &:focus {
              box-shadow: none;
            }
            &::after {
              content: '';
              position: relative;
              right: 0px;
              bottom: 4px;
              border: none;
              width: 10px;
              height: 30px;
              background-image: url('../../../../../assets//icons/ArrowDown.svg');
              background-position: center;
              background-repeat: no-repeat;
              background-size: 10px;
            }
          }
          & > ul {
            right: 0;
            bottom: 32px;
            min-width: 50px;
            li {
              a {
                text-decoration: none;
                font-weight: 400;
                font-size: 14px;
                color: #252830;
              }
            }
          }
        }
      }
    }
  }
}
.productName {
  color: #0044bb;
  cursor: pointer;
  width: fit-content;

  img {
    vertical-align: text-bottom;
  }
}

.split {
  &:not(:first-of-type) {
    margin-top: 12px;
  }
  display: flex;
  justify-content: space-between;
}

.info_container {
  font-style: normal;
  font-size: 16px;
  color: #606060;
  .title {
    font-weight: 600;
  }
  .info {
    font-weight: 400;
  }
}

.col_3 {
  width: 33.333%;
}
