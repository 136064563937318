.Inline {
    display: inline-block;
    position: relative;
}

.Badge {
    width: 20px;
    height: 20px;
    font-size: 14px;
    line-height: normal;
    position: absolute;
    top: -5px;
    right: -5px;
    background-color: var(--accent);
    color: var(--white);
    border: 2px solid var(--primary-scale-100);
    border-radius: 50%;
    text-align: center;
}
