@import "/src/styles/core";

.custom-editor {
    border-radius: 0 0 4px 4px;
    #fr-logo {
        display: none;
    }

    .fr-element {
        p {
            margin-bottom: 0.25rem;
        }
    }

    &.error {
        .fr-toolbar {
            border: 1px solid var(--error);
            border-bottom: none;
        }

        .fr-wrapper {
            border: 1px solid var(--error);
            border-top: none;
            border-bottom: none;
        }

        .fr-second-toolbar {
            border: 1px solid var(--error);
            border-top: none;
        }

        .fr-element {
            background-color: var(--error-dimmed);
        }
    }
}

.custom-wysiwyg {
    margin: 1rem 0 0 0;

    .custom-toolbar {
        background-color: #FBFBFB;
        border-color: transparent;
        border-radius: 6px 6px 0 0;
        border-bottom: 1px solid #f1f1f1;

        position: relative !important;
        z-index: 99 !important;

        .rdw-option-wrapper {
            border: none;
            background-color: transparent;
            width: 30px;
            height: 30px;

            &:hover {
                background-color: #c0c0c073;
                box-shadow: none;
            }
        }

        .rdw-dropdown-wrapper {
            background-color: #FBFBFB;
            border-color: transparent;
            &:hover {
                background-color: #c0c0c073;
                box-shadow: none;
            }
        }
    }

    &.borderless-editor {
        .custom-wrapper {
            border: none;
            border-radius: 4px;
            background-color: white;
        }
    }

    &:not(.borderless-editor) {
        .custom-wrapper {
            border: 1px solid var(--outline-scale-100);
            border-radius: 4px;
            background-color: white;
        }
    }

    .custom-wrapper-error {
        border: 1px solid var(--error);
        background-color: var(--error-dimmed);
        border-radius: 4px 4px 0 0;
    }

    .custom-editor {
        &.rdw-editor-main {
            height: 80px;
            overflow-y: auto;
            padding: 0 1.5em;
            background-color: white;
        }

        .public-DraftStyleDefault-block {
            margin: 0 !important;
        }
    }

    &.error {
        .custom-wrapper {
            border: 1px solid var(--error);
            background-color: var(--error-dimmed);
            border-radius: 0.5rem;
        }
    }

    &.small-editor {
        .custom-editor {
            &.rdw-editor-main {
                height: 140px !important;
            }
        }
    }
}

@include media-breakpoint-up(md) {
    .small-editor {
        .custom-editor {
            &.rdw-editor-main {
                height: 140px !important;
            }
        }
    }

    :not(.small-editor) {
        .custom-wysiwyg {
            .custom-editor {
                &.rdw-editor-main {
                    height: 200px;
                }
            }
        }
    }
}
