.Container {
    width: 90%;
    padding-top: 1rem;

    h5 {
        font-weight: 700;
        font-size: 18px;
        color: var(--primary-scale-90);
    }

    .Section {
        margin-bottom: 2.5rem;

        .Title {
            color: var(--accent);
            font-size: 20px;
            font-weight: 700;
            margin-bottom: 1.5rem;
            margin-top: 3rem;
        }

        p {
            margin-bottom: 1.5rem;
        }
    }
}
