.Hamburger {
    border: none;
    background-color: transparent;
    outline: none;
    cursor: pointer;
    padding: 0.5rem;
    border-radius: 50%;
    width: 40px;
    height: 40px;

    span {
        display: block;
        height: 2px;
        margin: 4px 0;
        position: relative;
        border-radius: 3px;
    }

    &.Close {
        span {
            opacity: 1;
            transform: rotate(45deg) translate(-5px, -4px);
            background: #232323;
        }

        span:nth-last-child(3) {
            opacity: 0;
            transform: rotate(0deg) scale(0.2, 0.2);
        }

        span:nth-last-child(2) {
            transform: rotate(-45deg) translate(0, -1px);
        }
    }

    &.Light {
        &:hover {
            background-color: var(--white-hover);
        }

        span {
            background: var(--white) !important;
        }
    }

    &.Accent {
        &:hover {
            background-color: var(--accent-hover);
        }

        span {
            background: var(--accent) !important;
        }
    }
}
