.custom-select {
    &:active:not(.light) {
        div:first-of-type {
            border-color: var(--accent) !important;
        }
    }

    &.light:active {
        div:first-of-type {
            border-color: red !important;
        }
    }

    &.borderless-select {
        .custom-select__control {
            border: none;
        }
    }

    &.select-input-group {
        .custom-select__control {
            border-top-right-radius: 0 !important;
            border-bottom-right-radius: 0 !important;
            min-width: 55px;
        }
    }
}

.custom-select__menu {
    z-index: 9999 !important;
    .custom-select__option--is-selected {
        background-color: var(--accent);
    }
}
