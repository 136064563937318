.Container {
    margin-top: 1rem;

    .Tabs {
        // border-bottom: 1px solid var(--outline-scale-100) !important;
        margin: 0;
        padding: 0;
        list-style: none;
        display: flex;
    }
}
