.modal_wrapper {
  .custom_header {
    background: #f2f2f2;
    border-bottom: none;
    display: flex;
    align-items: center;
    justify-content: center;
    & > div {
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      color: #323232;
    }
  }
  .custom_body {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #323232;
    padding: 16px;
    .attach_images_wrapper {
      display: grid;
      grid-template-columns: 100%;
      row-gap: 12px;
      .attach_wrapper {
        display: grid;
        grid-template-columns: 72px auto;
        column-gap: 13px;
        .image_wrapper {
          border: 1px solid #e3e7ee;
          border-radius: 4px;
          width: 72px;
          height: 72px;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          background: #f8f8f8;
          .remove_image {
            cursor: pointer;
            position: absolute;
            background: rgba(227, 231, 238, 0.7);
            border-radius: 100%;
            width: 26px;
            height: 26px;
            display: flex;
            align-items: center;
            justify-content: center;
            bottom: 10px;
            .icon {
              width: 15px;
              height: 15px;
              mask-image: url('../../../../../../../assets/icons/TrashIcon.svg');
              mask-size: contain;
              mask-repeat: no-repeat;
              background: #25262d;
            }
          }
          label {
            padding: 0;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
          }
          &.no_image {
            .remove_image {
              display: none;
            }
            label {
              img {
                max-width: 70px;
                max-height: 70px;
                border-radius: 4px;
              }
            }
          }
          img {
            max-width: 70px;
            max-height: 70px;
            border-radius: 4px;
            cursor: pointer;
          }
          .icon_wrapper {
            position: absolute;
            cursor: pointer;
            width: 26px;
            height: 26px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: rgba(227, 231, 238, 0.7);
            border-radius: 100%;
            .icon {
              width: 12px;
              height: 12px;
              --webkit-mask-image: url('../../../../../../../assets/icons/TrashIcon.svg');
              --webkit-mask-size: contain;
              --webkit-mask-repeat: no-repeat;
              mask-image: url('../../../../../../../assets/icons/TrashIcon.svg');
              mask-size: contain;
              mask-repeat: no-repeat;
              background: #25262d;
            }
          }
        }
        .link_wrapper {
          .text {
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            color: #25262d;
          }
          .link_input {
            margin-top: 6px;
            input {
              background: #ffffff;
              border: 1px solid #e3e7ee;
              border-radius: 4px;
              padding: 8px;
              width: 100%;
              font-style: normal;
              font-weight: 500;
              font-size: 16px;
              color: #005eff;
              &:focus {
                border-color: #e3e7ee !important;
              }
            }
          }
        }
      }
    }
    .add_more_btn_wrapper {
      margin-top: 12px;
      display: flex;
      align-items: center;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      cursor: pointer;
      .icon_wrapper {
        border: 1px dashed #e3e7ee;
        border-radius: 4px;
        width: 72px;
        height: 72px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        background: #f8f8f8;
        margin-right: 13px;
        img {
          width: 29px;
        }
      }
    }
  }
  .custom_footer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 11px;
    button {
      min-width: 150px;
      height: 44px;
      border: none;
      border-radius: 6px;
      &.cancel_btn {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        color: #0044bb;
        background: transparent;
      }
      &.confirm_btn {
        background: #0044bb;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        color: #fbfbfb;
      }
    }
  }
}
