.custom-popover-container {
    border: none;
    box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.12);
    border-radius: 16px;

    max-width: 350px !important;

    &.custom-popover-square {
        border-radius: 0 !important;
    }

    .popover-arrow {
        &::before {
            border-bottom-color: transparent !important;
        }
    }
}
