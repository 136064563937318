.LoginForm {
    padding: 3rem 1rem;
    position: relative;
    z-index: 10;
    background-color: var(--primary-scale-70);

    .NotRegistered {
        a {
            text-decoration: none;
            border-bottom: 1px solid var(--accent);
        }
    }
}

.Separator {
    .Line {
        border: 1px solid var(--outline-scale-80);
        height: 2px;
    }

    .Text {
        width: 40px;
        height: 40px;
        border: 2px solid var(--outline-scale-80);
        border-radius: 50%;
        font-size: 14px;
    }
}

.LoginLogo {
    img {
        position: absolute;
        right: 0;
        margin-top: 2rem;
    }
}

.DisabledLink {
    pointer-events: none;
    cursor: not-allowed;
}

.Pointer {
    cursor: default;
}

.FailedLogin {
    font-size: 0.9rem;
    padding: 0.5rem 0 1rem 0;
}
