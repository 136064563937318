@import '/src/styles/core';

.StepTitle {
  border-bottom: 1px solid var(--background-accent);
}

.StepFormControls {
  input {
    border: 1px solid var(--outline-scale-80);

    &:active,
    &:focus {
      box-shadow: none;
      border: 1px solid var(--outline-scale-80);
    }
  }
}

.company_info_wrapper {
  label {
    padding-bottom: 4px;
    font-weight: 400;
    font-size: 14px;
    color: #323232;
  }
  textarea {
    background: #ffffff;
    border: 1px solid #e0e0e0;
    border-radius: 6px;
    font-weight: 400;
    font-size: 16px;
    color: #323232;
    width: 100%;
    max-width: 832px;
    padding: 10px 8px;
    resize: none;
    outline: none;
  }
}

.PhoneInput {
  display: block;
  margin-bottom: 1rem;
}

.PhoneInput > input {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  appearance: none;
  background-color: var(--bs-body-bg);
  background-clip: padding-box;
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.PhoneInputError {
  display: block;
  margin-bottom: 0;
}

.centered {
  text-align: center;
}

.PhoneInputError > input {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  appearance: none;
  background-clip: padding-box;
  border-radius: var(--bs-border-radius);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  border: 1px solid var(--error) !important;
  background-color: #ff000017 !important;
}

.PhoneErrorInput {
  border: 1px solid var(--error) !important;
  background-color: #ff000017 !important;
}

.PhoneInputErrorText,
.error {
  font-size: 0.75rem;
  color: var(--error) !important;
  margin-bottom: 1rem;
}

.RequiredIndicator {
  color: var(--accent);
  font-weight: bold;
}

.StepSection2 {
  border-bottom: 1px solid var(--background-accent);
}

.FormGrid {
  display: grid;
  column-gap: 2rem;
  grid-template-columns: 1fr;
  grid-template-areas:
    'sectionOne'
    'sectionTwo'
    'sectionThree';
}

.Section1 {
  grid-area: sectionOne;
}

.Section2 {
  grid-area: sectionTwo;
}

.Section3 {
  grid-area: sectionThree;
  max-width: 200px;
}

.CompanyIndustryError {
  font-size: 0.75rem;
  color: var(--error) !important;
}

.MarginSeparator {
  margin-bottom: 0.5rem;
}

.ReCaptcha {
  margin-top: 1rem;

  .Label {
    font-size: 14px;

    &.Error {
      color: var(--error);
    }

    span {
      color: var(--accent);
    }
  }

  .CodeInput {
    display: flex;
    align-items: center;
    border: 1px solid var(--outline-scale-80);
    border-radius: 0.25rem;
    padding: 0.375rem 0.5rem;

    input {
      border: none;
      outline: none;
      width: 100px;
      color: var(--accent);
    }

    button {
      border: none;
      outline: none;
      background-color: transparent;
      color: var(--accent);
      flex: 1;
      justify-content: flex-end;
      font-weight: 500;
      font-size: 14px;
      text-align: right;
    }
  }

  .GeneratedCode {
    display: flex;
    gap: 1rem;
    align-items: center;
    padding: 0.375rem 0rem;

    img {
      cursor: pointer;
    }

    .Container {
      position: relative;

      img {
        position: absolute;
        width: 100%;
        z-index: 0;
      }

      .Skew {
        transform: skew(8deg, 6deg);
      }
    }
  }
}

.notificationLabel {
  font-size: 13px;
  color: green;
}

.image_container {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .image_wrapper {
    width: 100px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      max-width: 100px;
      max-height: 100px;
    }
  }
  .upl_img_wrapper {
    display: flex;
    align-items: center;
    margin-top: 10px;
    padding: 8px 7px;
    justify-content: center;
    vertical-align: middle;
    border-radius: 5px;
    border: 1px solid #0044bb;
    background-color: white;
    cursor: pointer;

    .icon {
      width: 20px;
      height: 20px;
      background-image: url('../../../../assets/icons/Upload.svg');
      background-position: center;
      background-size: auto;
      cursor: pointer;
    }
    .text {
      margin-left: 10px;
      font-weight: 500;
      font-size: 16px;
      color: #0044bb;
      padding: 0;
      text-align: center;
      cursor: pointer;
    }
    &:hover {
      .text,
      .icon {
        color: white;
      }
      background-color: #0044bb;
    }
  }
}
.tab_one {
  display: grid;
  grid-template-columns: minmax(auto, 400px) minmax(auto, 400px);
  column-gap: 32px;
  margin-top: 23px;
  .col_one,
  .col_two {
    .input_wrapper {
      &:not(:first-child) {
        margin-top: 5px;
      }
      //   &.has_error {
      //     input {
      //       border-color: #ff0000 !important;
      //       &:focus,
      //       &:active {
      //         border-color: red !important;
      //       }
      //     }
      //   }
      label {
        padding-bottom: 4px;
        font-weight: 400;
        font-size: 14px;
        color: #323232;
        span {
          color: #0044bb;
        }
      }
      input {
        background: #ffffff;
        border: 1px solid #f2f2f2;
        border-radius: 6px;
        width: 100%;
        height: 40px;
        padding: 10px 8px;
        font-weight: 400;
        font-size: 16px;
        color: #323232;
        &:focus,
        &:active {
          border-color: #f2f2f2;
        }
        &:disabled {
          border-color: #f2f2f2 !important;
          background: #fbfbfb !important;
        }
      }
      // .error {
      //     color: #FF0000;
      //     font-weight: 400;
      //     font-size: 12px;
      //     height: 18px;
      //     margin-top: 2px;
      // }
    }
    .inputs_container {
      display: grid;
      grid-template-columns: calc(50% - 3.5px) calc(50% - 3.5px);
      column-gap: 7px;
      .input_wrapper {
        margin-top: 5px;
      }
    }
  }
}
.company_info_wrapper {
  label {
    padding-bottom: 4px;
    font-weight: 400;
    font-size: 14px;
    color: #323232;
  }
  textarea {
    background: #ffffff;
    border: 1px solid #e0e0e0;
    border-radius: 6px;
    font-weight: 400;
    font-size: 16px;
    color: #323232;
    width: 100%;
    max-width: 832px;
    padding: 10px 8px;
    resize: none;
    outline: none;
  }
}
.current_state_wrapper {
  margin-top: 15px;
  label {
    font-weight: 500;
    font-size: 16px;
    color: #323232;
  }
  .file_wrapper {
    display: flex;
    flex-direction: column;
    width: 235px;
    max-width: 100%;
    height: 161px;
    border: 1px solid rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    &.no_file {
      justify-content: flex-end;
      height: auto;
      .file_text {
        align-items: center;
        padding: 0;
        .file_info {
          .name {
            text-decoration-line: none !important;
            white-space: normal;
            overflow: unset;
            text-overflow: unset;
            max-width: none;
          }
        }
      }
    }
    .file_image {
      height: 115px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    }
    .file_text {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .file_info {
        padding: 5px 14px;
        .name {
          font-weight: 500;
          font-size: 14px;
          text-decoration-line: underline;
          color: #323232;
          line-height: normal;
          cursor: pointer;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 120px;
        }
        .size {
          font-size: 14px;
          color: #828282;
          line-height: normal;
        }
      }
      .actions {
        display: flex;
        align-items: center;
        padding: 5px 14px;
        .attach {
          width: 24px;
          height: 24px;
          -webkit-mask-image: url('../../../../assets/icons/Attach_icon.svg');
          -webkit-mask-repeat: no-repeat;
          -webkit-mask-position: center;
          mask-image: url('../../../../assets/icons/Attach_icon.svg');
          mask-repeat: no-repeat;
          mask-position: center;
          background: #0044bb;
          cursor: pointer;
        }
        .remove {
          cursor: pointer;
          margin-left: 10px;
          width: 24px;
          height: 24px;
          -webkit-mask-image: url('../../../../assets/icons/TrashIcon.svg');
          -webkit-mask-repeat: no-repeat;
          mask-image: url('../../../../assets/icons/TrashIcon.svg');
          mask-repeat: no-repeat;
          background: #0044bb;
        }
      }
    }
  }
}
.more_ads_info_wrapper {
  margin-top: 15px;
  label {
    font-weight: 500;
    font-size: 16px;
    color: #323232;
    padding: 0;
  }
  .checkboxes_wrapper {
    margin-top: 5px;
    .checkbox_wrapper {
      display: flex;
      align-items: center;
      max-width: 832px;
      width: fit-content;
      &:not(:last-of-type) {
        margin-bottom: 5px;
      }
      position: relative;
      cursor: pointer;
      font-size: 22px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      input {
        opacity: 0;
        cursor: pointer;
        height: 16px;
        width: 16px;
        &:checked ~ .checkmark {
          background-color: #9f9f9f;
          &:after {
            display: block;
          }
        }
      }
      .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 16px;
        width: 16px;
        background-color: #eee;
        border-radius: 2px;
        border: 1px solid #d9d9d9;
        &:after {
          content: '';
          position: absolute;
          display: none;
          left: 4.5px;
          top: 1.5px;
          width: 4px;
          height: 9px;
          border: solid #ffffff;
          border-width: 0 2px 2px 0;
          -webkit-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
          transform: rotate(45deg);
        }
      }
      .text {
        padding: 0;
        margin-left: 8px;
        font-weight: 400;
        font-size: 14px;
        color: #323232;
      }
    }
  }
}
.tab_two {
  display: grid;
  grid-template-columns: minmax(auto, 400px) minmax(auto, 400px);
  column-gap: 32px;
  margin-top: 57px;
  padding-bottom: 22px;
  .col_one,
  .col_two {
    .input_wrapper {
      &:not(:first-child) {
        margin-top: 5px;
      }
      //   &.has_error {
      //     input {
      //       border-color: #ff0000 !important;
      //       &:focus,
      //       &:active {
      //         border-color: red !important;
      //       }
      //     }
      //   }
      label {
        padding-bottom: 4px;
        font-weight: 400;
        font-size: 14px;
        color: #323232;
        span {
          color: #0044bb;
        }
      }
      input {
        background: #ffffff;
        border: 1px solid #f2f2f2;
        border-radius: 6px;
        width: 100%;
        height: 40px;
        padding: 10px 8px;
        font-weight: 400;
        font-size: 16px;
        color: #323232;
        &:focus,
        &:active {
          border-color: #f2f2f2;
        }
        &:disabled {
          border-color: #f2f2f2 !important;
          background: #fbfbfb !important;
        }
      }
      .error {
        color: #ff0000;
        font-weight: 400;
        font-size: 12px;
        height: 18px;
        margin-top: 2px;
      }
    }
  }
}

.input_wrapper {
  &.has_error {
    input {
      border-color: #ff0000 !important;
      &:focus,
      &:active {
        border-color: red !important;
      }
    }
  }
  label {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #404040;
    padding-bottom: 5px;
  }
  input {
    width: 100%;
    background: #ffffff;
    border: 1px solid #dfdfdf;
    border-radius: 6px;
    padding: 8px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #323232;
    &:focus,
    &:active {
      border-color: #dfdfdf !important;
    }
  }
  .error {
    color: #ff0000;
    font-weight: 400;
    font-size: 12px;
    height: 18px;
    margin-top: 2px;
  }
}

#captcha {
  background-image: url('../../../../assets/worn-dots.png');
}

.Footer {
  border-top: 1px solid var(--background-accent);
  padding-top: 1rem;

  button {
    width: 100% !important;
  }
}

.tabs_wrapper {
  margin-bottom: 40px;
  display: grid;
  grid-template-columns: max-content auto;
  align-items: center;
  border-bottom: 1.5px solid var(--outline-scale-100);
  .title {
    font-weight: 700;
    font-size: 18px;
    color: #252830;
  }
  nav {
    margin-top: 0;
    ul {
      border-bottom: none !important;
      justify-content: flex-end;
      li {
        padding: 5px 21px;
        font-weight: 400;
        font-size: 19px;
        color: #323232;
        border-bottom: 1.5px solid transparent;
        transition: 0.26s;
        &[class*='Tab_Active'] {
          font-weight: 700;
          color: #0044bb;
          border-bottom: 1.5px solid #0044bb;
        }
        &::before {
          display: none;
        }
        &:hover {
          color: white;
          border-radius: 5px;
          background-color: #0044bb;
        }
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .Footer {
    justify-content: right;

    button {
      width: max-content !important;
    }
  }

  // .CancelButton {
  //     margin-right: -3rem !important;
  // }
}

@include media-breakpoint-up(md) {
  .FormGrid {
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      'sectionOne sectionTwo'
      'sectionThree .';
  }
}

@include media-breakpoint-up(lg) {
  .FormGrid {
    grid-template-columns: 1fr 1fr auto;
    grid-template-areas: 'sectionOne sectionTwo sectionThree';
  }
}
