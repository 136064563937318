.label-style {
    margin-top: 8px;
    font-weight: 400;
    font-size: 16px;
}

.text-size-18 {
    font-weight: 600;
    font-size: 18px;
    color: #404040;
}

.back_btn {
    background: #FFFFFF;
    border-radius: 16px;
    height: 30px;
    margin: 33px 0 0 0.75rem;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    text-decoration-line: underline;
    color: #0044BB;
    border: none;
    padding: 4px 10px;
    text-underline-offset: 2px;
};

.info_title {
    font-weight: 700;
    font-size: 18px;
    color: #252830;
}

.company_info_wrapper {
    margin-top: 20px;
    padding-bottom: 22px;
    display: grid;
    grid-template-columns: auto 360px;
    @media screen and (max-width: 767px) {
        grid-template-columns: auto;
    }
    .info_wrapper {
        .name_label {
            font-weight: 400;
            font-size: 16px;
            color: #808080;
        }
        .name {
            @extend .text-size-18;
            margin-top: 8px;
        }
        .website {
            margin-top: 8px;
            font-weight: 400;
            font-size: 16px;
            text-decoration-line: underline;
            color: #0051B8;
        }
        .email {
            @extend .label-style;
            color: #606060;
        }
        .address {
            @extend .label-style;
            color: #606060;
        }
        .phone {
            @extend .label-style;
            color: #606060;
        }
        .edb_label {
            @extend .label-style;
            color: #808080;
        }
        .edb {
            @extend .text-size-18;
            margin-top: 8px;
        }
        .mbeo_label {
            @extend .label-style;
            color: #808080;
        }
        .mbeo {
            @extend .text-size-18;
            margin-top: 8px;
        }
        .info_label {
            @extend .label-style;
            color: #808080;
        }
        .info {
            @extend .label-style;
            color: #323232;
        }
    }
    .image_wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        @media screen and (max-width: 767px) {
            margin-top: 40px;
        }
        .image {
            min-width: 158px;
            max-width: 158px;
            min-height: 158px;
            max-height: 158px;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
                max-width: 158px;
                max-height: 158px;
            }
        }
        .company_name {
            margin-top: 43px;
            font-weight: 700;
            font-size: 27px;
            color: #323232;
        }
    }
}