.notification_wrapper {
    flex: 1;
    padding: 10px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &.success {
        background: #41CA00;
    }
    &.danger {
        background: #FC3333;
    }
    .message {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        color: #FFFFFF;
    }
    .icon_wrapper {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background: #E0E0E0;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        .close_button {
            mask-image: url("./../assets/icons/Close.svg");
            mask-repeat: no-repeat;
            mask-size: 10px;
            background: #828282;
            width: 10px;
            height: 10px;
        }
    }
}