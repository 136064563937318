.pagination {
    li {
        &.active {
            a {
                background: #0044BB !important;
                color: #FBFBFB !important;
                border-color: #0044BB !important;
            }
        }
        &.disabled {
            a {
                color: #D9D9D9 !important;
            }
        }
    }
}